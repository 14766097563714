<template>
  <div class="rb-page pr-pol">
    <div
      class="container feedback-wrap"
      :class="{ unselectable: isGuest }"
      @contextmenu="interact"
      @copy="interact"
      @cut="interact"
    >
      <div class="white-box">
        <div class="row">
          <div v-if="sex.isWoman" class="col-12">
            <h1 class="rb-page--header">Политика анти-мошенничества</h1>
            <p>
              Мы гордимся, что наше браузерное приложение
              <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
              является брендом высокого качества и мы стараемся поддерживать его на должном уровне.
              Для обеспечения защиты наших пользователей во время использования веб-сайта наша
              администрация придерживается политики борьбы с мошенничеством. Очень большое значение
              для нас имеет доверие наших пользователей.
            </p>
            <p>
              Наш веб-сайт сотрудничает с CPA (Cost Per Action) партнерами, которые занимаются
              привлечением пользователей женского пола. Все эти пользовательницы – представляют
              собой незамужних и совершеннолетних девушек, которым необходимы новые знакомства.
              Девушки и женщины хотят заводить знакомства с мужчинами несмотря на их религиозные
              предпочтения, национальность или местопроживания и находятся в поиске серьезных и
              долговременных отношений. Для зарегистрированных клиенток пользование нашим веб-
              сервисом совершенно бесплатно. Однако их твердого намерения и наличия учетной записи,
              зарегистрированной через CPA партнера, недостаточно для того, чтобы их профайлы на
              нашем веб-сайте стали активными, потому что пользователи должны пройти процедуру
              проверки для подтверждения своей личности, цели регистрации профайла и права на
              контент, загруженный ними на сайт.
            </p>
            <ol class="rb-page--list rb-page--list__main list-unstyled">
              <li class="increment">
                <h2>Ситуации, которые не считаются мошенничеством:</h2>
                <ul class="ul-styled">
                  <li>
                    Если девушка принимает решение о прекращении отношений с Вами в любой момент
                    вашего общения;
                  </li>
                  <li>
                    В том случае, если пользовательница поменяла статус активности профайла на
                    веб-сайте;
                  </li>
                  <li>
                    Если активность профиля пользовательницы часто высока или наоборот профайл
                    длительно находится в офлайн режиме;
                  </li>
                  <li>
                    Если пользовательница носит кольцо. Из этого следует, что ношение кольца на
                    каком-либо пальце не означает, что она находится в браке;
                  </li>
                  <li>
                    Когда в любой из социальных сетей либо по электронной почте пользовательница не
                    реагирует на все ваши вопросы;
                  </li>
                  <li>
                    Когда девушка не отвечает на звонки и/или видеозвонки, осуществляемые с помощью
                    любых средств связи, доступными за пределами сайта;
                  </li>
                  <li>
                    Если пользовательница уклоняется от предоставления своих персональных фото
                    интимного характера по любым причинам;
                  </li>
                  <li>
                    Когда у пользовательницы есть фото друзей, родственников, бывших партнеров и
                    другая личная информация, размещенная в другой социальной сети, в том числе и на
                    нашем веб-сайте;
                  </li>
                  <li>
                    Когда фотография и/или информация о пользовательнице используется для
                    отрицательных отзывов на других сайтах. Исключением являются те веб-сайты,
                    которые имеют лицензию на осуществление деятельности по борьбе с мошенничеством.
                  </li>
                </ul>
              </li>
              <li class="increment">
                <h2>
                  Всем девушкам, которые хотят пользоваться нашим сервисом необходимо предоставить
                  данные своих учетных записей для процедуры проверки:
                </h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__a increment">
                    Во-первых, администрация сайта детально анализирует все текстовые данные,
                    которые используют для формирования профайла. CPA партнеры ответственны перед
                    нами за все содержание и его соответствие нашим правилам. CPA партнеры несут
                    ответственность за содержание личных страниц пользовательниц, которые
                    зарегистрировались на нашем сервисе через их партнерскую административную
                    панель. Во-вторых, все клиентки предоставляют нам электронные копии своих
                    документов, удостоверяющие их личности, а также мы проверяем адреса электронной
                    почты и мобильные контактные номера всех пользовательниц.
                  </li>
                  <li class="rb-page--list__a increment">
                    Мы проверяем соответствие между данными и фото, указанных в предоставленных нам
                    копиях документов, и контентом профайла (текстовым и мультимедийным),
                    находящегося на веб-сайте, с целью избежать использования профайла
                    пользовательницы другим человеком.
                  </li>
                  <li class="rb-page--list__a increment">
                    Если у администрации возникают какие-либо сомнения, связанные с достоверностью
                    данных, то мы имеем возможность проверить личность с помощью видеозвонка Skype
                    или через другой сервис видеосвязи, так как мы можем общаться и видеть всех
                    наших пользовательниц.
                  </li>
                  <li class="rb-page--list__a increment">
                    В случае, когда Вы сомневаетесь в добросовестности какой-либо девушки, для
                    начала удостоверьтесь, что это та же пользовательница, чей профайл Вы предпочли.
                    Далее, в видео чате Вы можете попросить ее включить веб-камеру для визуального
                    контакта. Однако если девушка длительное время отказывается включать камеру, а
                    также уклоняется от ответа почему она не хочет использовать веб-камеру, то с
                    помощью обратной связи с нашей администрацией или другим удобным способом Вы
                    можете пожаловаться на данную пользовательницу и мы детально расследуем этот
                    случай.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Несколько советов, как избежать общения с мошенницами:</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__a increment">
                    Не следует отправлять денежные суммы Вашей пассии до того, как Ваши отношения
                    вышли за пределы сайта. Можно не оспаривать тот факт, что большинство девушек
                    любят покупки, а некоторые из низ настоящие расточительницы. Поэтому не следует
                    провоцировать их своими финансовыми возможностями. Вполне возможно, что получив
                    ваши деньги они начнут тратить их на косметику или одежду.
                  </li>
                  <li class="rb-page--list__a increment">
                    Не спешите с выводами, полагая что вы влюбились в девушку через 1 или 2 месяца
                    общения. Более того, не думайте, что ваша любовь взаимная, не смотря на то, что
                    девушка так говорит. Чтобы узнать о чувствах девушки, просите ее чаще включать
                    видео-чат, чтобы вы могли видеть свою избранницу, или пробуйте выражать свои
                    мысли в виде писем и говорить с ней о чувствах в обычном чате. У вас есть все
                    причины считать, что вы узнали ближе ее, ее мысли и поведение, но вместе с этим
                    вы до конца не осознаете, каковы ее настоящие чувства и характер. Поэтому
                    встреча в реальной жизни остается самым надежным способом проверить не обманули
                    ли вас. Чтобы избежать обмана, лучше сразу приобрести контактные данные Вашей
                    избранницы и условиться о встрече. После этого с помощью нашего сервиса Вы
                    можете получить содействие в организации Вашего путешествия в страну где она
                    проживает или мы можем помочь Вашей даме приехать в Вашу страну. Крайне важно
                    провести со своей избранницей как можно больше времени в реальном мире (пусть
                    это будет как минимум неделя), чтобы понять, нужны ли вы друг другу или нет.
                  </li>
                  <li class="rb-page--list__a increment">
                    Помощь профессионального переводчика это один из инструментов, который поможет
                    избежать мошенников, а также облегчит поиск Вашей любви. При общении с девушкой,
                    Вы считаете, что между Вами 100% понимание, однако это может быть не так. Многие
                    наши клиентки не из англоязычных стран, они изучали английский язык еще в школе,
                    а некоторые из них продолжают учить язык в университете. При этом большинство
                    девушек уверены в отличных знаниях языка, хотя у них не хватает практики для
                    улучшения своих разговорных навыков. Чтобы понять Ваши сообщения, многие девушки
                    пользуются электронными онлайн переводчиками. Но несмотря на высокое качество
                    перевода, электронные переводчики не могут в точности передать Ваши мысли. Но
                    выход есть- можно организовать дополнительную языковую подготовку
                    пользовательниц. К сожалению, не у всех женщин есть шанс обучаться на языковых
                    курсах, но мы уверены, что это не должно стать препятствием для поиска
                    достойного спутника жизни на нашем сервисе. Наш долг - помочь нашим
                    пользователям, и с этой целью мы рекомендуем при живых встречах пользоваться
                    услугами профессиональных переводчиков, которые придут на помощь, когда у
                    кого-либо из участников встречи возникнет проблема с пониманием иностранной
                    речи. Кроме того, официально приглашенный переводчик имеет обязательство перед
                    Вами. Если Вы желаете встретить свою избранницу без уведомления нашего сервиса,
                    то наша команда напоминает, что Вы делаете это на свой страх и риск.
                  </li>
                  <li class="rb-page--list__a increment">
                    В случае, когда Вам нужно удостовериться, что девушка с которой Вы общаетесь в
                    чате и та красивая девушка, которую Вы видите на фото является одним и тем же
                    человеком, Вам следует чаще приглашать ее в видео-чат. Как правило мошенники
                    используют украденные фотографии девушек и вводят в заблуждение
                    пользователей-мужчин от имени пользовательниц. Поэтому если Вы попросите их
                    прислать новые фото, то обманщикам негде будет их взять. Чтобы не сталкиваться с
                    подобными ситуациями, просите свою избранницу присылать Вам много свежих
                    фотографий. Но помните о том, что многие порядочные женщины, чьи фотографии были
                    украдены и использовались для обмана мужчин, состоят в «черных списках», как
                    мошенницы. При этом они сами являются жертвами и даже не подозревают это.
                  </li>
                  <li class="rb-page--list__a increment">
                    Девушки или женщины, могут находятся в браке, о чем они указывают в пункте о
                    семейном статусе, и, скорее всего, не ищут спутника жизни, но они заинтересованы
                    в общении и практике иностранных языков. Некоторые из них могут скрывать свое
                    семейное положение. Они изменяют своим мужьям и вряд ли могут быть для Вас
                    хорошими партнерами в будущем. Мы рекомендуем избегать таких женщин. Женщина
                    может скрыть факт замужества, так как она - мошенница и хочет обмануть Вас.
                    Крупные социальные сети для знакомств, такие как {{ siteName }}, ценят свою репутацию,
                    проверяют данные пользовательниц, чтобы убедиться в их совершеннолетии в своей
                    стране и в том, что они не состоят в браке. Только холостые женщины могут стать
                    нашими пользовательницами.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <p>
                  В интернете можно найти много сайтов с «черными списками» мошенниц, которые
                  обманывали мужчин. На таких сайтах обманутые мужчины размещают фотографии
                  бессовестных женщин и жалуются на них. Некоторым из этих списков вполне можно
                  доверять, но случается, что обиженные мужчины таким способом хотят отомстить тем
                  девушках, которые не ответили им взаимностью или отвергли их. Бывает что мужчины
                  оплачивают услуги анти-мошеннических сайтов для того, чтобы информация о таких
                  женщинах была внесена в «черный список». Поэтому нельзя точно определить, кто на
                  самом деле заслуживает доверия, и в связи с этим {{ siteName }} рекомендует не
                  использовать аналогичные сайты.
                </p>
              </li>
              <li class="increment">
                <p>
                  Многие социальные сети для знакомств и общения делают все возможное, чтобы
                  обезопасить своих пользователей от мошенничества. Они детально проверяют контент
                  (текстовый и мульимедийный), а также прочие документы, но для создания крепких
                  отношений между мужчиной и женщиной этих усилий недостаточно. Ни один из сервисов
                  никогда не даст Вам 100% гарантии того, что Вы совершенно подойдете друг другу.
                  Ваши отношения имеют шанс перерасти в счастливый брак или наоборот обернуться
                  разочарованием, независимо реальные они либо виртуальные. Никто не застрахован от
                  скверного характера, вредных привычек и неделикатного поведения своего партнера.
                  Но несмотря на это, для нашего веб-сайта крайне важно, чтобы наши клиенты
                  чувствовали себя защищенными от мошенничества. В интернете с фактом мошенничества
                  может столкнуться каждый, но никто не хочет стань жертвой обмана. Цели и
                  обязательства администрации нашего сайта заключаются в защите интересов наших
                  пользователей в случае любых неприятностей, связанных с аферистами.
                </p>
              </li>
              <li class="increment">
                <p>
                  В тех случаях, когда Вы можете стать жертвой жульничества вследствие использования
                  нашего веб-сайта и/или его сервисов, то на свое личное усмотрение наша компания
                  имеет возможность покрыть часть кредитов или равнозначную сумму наличными, которую
                  Вы израсходовали в процессе коммуникации с мошенницей. Это реально только в том
                  случае, если факт мошенничества можно проверить и подтвердить. В любом случае,
                  подобные ситуации очень редко встречаются на нашем веб-сайте, в чем мы уверены.
                  Наша компания беспокоится и защищает безопасность своих клиентов. Для нас очень
                  важно, чтобы Вы нашли свою вторую половинку, свое счастье и любовь через {{ siteName }}.
                  Мы выполняем все возможное, чтобы сделать Ваш поиск безопасным и плодотворным.
                </p>
              </li>
            </ol>
          </div>
          <div v-else class="col-12">
            <h1 class="rb-page--header">Anti-Scam Policy</h1>
            <p>
              We are proud that our web application “
              <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
              ” is a brand of high quality and we try to preserve it the proper level. To ensure our
              users to be protected from scammers while using the website our administration keeps
              the policy against the scam. The trust of our users is very important to us.
            </p>
            <p>
              Our website cooperates with CPA (Cost Per Action) partners who are engaged in
              attracting female users. All these users are unmarried and adult girls who need new
              acquaintances. Girls and women want to make acquaintances with men despite their
              religious preferences, nationality or place of residence and are in search of serious
              and long-term relationships. The registered customers can use our web service for
              free. However, their firm intention and having an account, registered through the CPA
              partner, for their profiles on our website to become active, because users must go
              through a verification procedure to confirm their identity, the purpose of registering
              the profile and the rights to the content uploaded by them to the site.
            </p>
            <ol class="rb-page--list rb-page--list__main list-unstyled">
              <li class="increment">
                <h2>Situations that are not considered as scamming</h2>
                <ul class="ul-styled">
                  <li>
                    If the girl decides to end the relationship with you at any time during your
                    communication;
                  </li>
                  <li>
                    In case when the girl or women have changed the status of her profile activity
                    on the website;
                  </li>
                  <li>
                    If the activity of the user’s profile is often high or, on the contrary, the
                    profile is long offline;
                  </li>
                  <li>
                    If the user wears a ring. It follows that wearing a ring on any finger does not
                    mean that she is married;
                  </li>
                  <li>
                    When in any of the social networks or by means of e-mail the user does not
                    respond to all your questions;
                  </li>
                  <li>
                    When a girl does not answer calls and / or video calls made by any means of
                    communication available outside the site;
                  </li>
                  <li>
                    If the user refuses to provide her personal photos of an intimate nature for any
                    reason;
                  </li>
                  <li>
                    When a user has photos of friends, relatives, former partners and other personal
                    information posted on another social network, including on our website
                  </li>
                  <li>
                    When photo and / or user information is used for negative reviews on other
                    sites. The exceptions are those websites that are licensed to carry out
                    anti-scam activities.
                  </li>
                </ul>
              </li>
              <li class="increment">
                <h2>
                  All girls who want to use our service must provide their account details for the
                  verification procedure:
                </h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__a increment">
                    Firstly, the site administration analyzes in detail all the text data that is
                    used to form a profile. CPA partners are liable to us for all content and its
                    compliance with our rules. CPA partners are responsible for the content of the
                    personal pages of users who have registered on our service through their
                    affiliate administrative panel. Secondly, all clients provide us with electronic
                    copies of their documents proving their identity, and we also check email
                    addresses and mobile contact numbers of all users.
                  </li>
                  <li class="rb-page--list__a increment">
                    We check the correspondence between the data and the photos indicated in the
                    copies of the documents provided to us and the content of the profile (text and
                    multimedia) posted on the website in order to avoid using the profile of the
                    user by another person.
                  </li>
                  <li class="rb-page--list__a increment">
                    If the administration has any doubts related to the accuracy of the data, then
                    we are able to verify the identity using a Skype video call or through another
                    video calling service, since we can communicate and see all of our users.
                  </li>
                  <li class="rb-page--list__a increment">
                    In case you doubt the good faith of any girl, first make sure that this is the
                    same user whose profile you have preferred. Further, in the video chat, you can
                    ask her to turn on the webcam for visual contact. However, if a girl refuses to
                    turn on the camera for a long time, and also refuses to answer why she does not
                    want to use the webcam, then you can complain about this user through feedback
                    with our administration or by other convenient means and we will investigate
                    this case in detail.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>A few tips on how to avoid communication with scammers:</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__a increment">
                    You should not send the money toyour passion before your relationship has gone
                    beyond the site. You can not dispute the fact that most girls love shopping, and
                    some of them are real beggars. Therefore, you should not provoke them with your
                    financial capabilities. It is possible that after receiving your money, they
                    will begin to spend it on cosmetics or clothing.
                  </li>
                  <li class="rb-page--list__a increment">
                    Do not rush to conclusions, believing that you fell in love with a girl after 1
                    or 2 months of communication. Moreover, do not think that your love is mutual,
                    despite the fact that the girl says so. To find out about a girl's feelings, ask
                    her to turn on video chat more often, so that you can see your darling, or try
                    to express your thoughts in the form of letters and talk to her about feelings
                    in a regular chat. You have all reasons to believe that you know her in better
                    way, know her thoughts and behavior, but at the same time you are not fully
                    aware of her true feelings and character. Therefore, a meeting in real life
                    remains the most reliable way to check whether you have not been deceived.To
                    avoid scam, it is better to immediately get the contact details of your darling
                    and arrange a meeting. After that, with the help of our service, you can get
                    assistance in organizing your trip to the country where she lives or we can help
                    your lady come to your country. It is extremely important to spend as much time
                    with your darling as possible in the real world (let it be at least a week) in
                    order to understand whether you need each other or not.
                  </li>
                  <li class="rb-page--list__a increment">
                    The help of a professional translator is one of the tools that will help avoid
                    scammers, as well as support the search for your love. When communicating with a
                    girl, you think that you have 100% understanding between, but this may be not
                    true. Many of our female clients are not from English-speaking countries, they
                    studied English at school, and some of them continue to learn the language at
                    the university. At the same time, most girls are confident in their excellent
                    knowledge of the language, although they do not have enough speaking practice to
                    develop their conversational skills. Moreover, some of the girls know only
                    primary English. To understand your messages, many girls use electronic online
                    translators. Nevertheless, even modern electronic translators can not convey
                    your thoughts reliably. But there is a way out; you can organize additional
                    language training for female users. Unfortunately, not all women have a chance
                    to study in language courses, but we are sure that this should not be an
                    obstacle to find a decent life partner on our service. Our obligation is to help
                    our men users, and for this purpose we recommend to use the services of
                    professional translators at live meetings, who will help to communicate when
                    someone amoung participants of the meeting has a problem with understanding
                    foreign speech. Moreover, the officially invited translator has a responsibility
                    to you. If you wish to meet your soul mate without advising our service, then
                    our team reminds you that you do this at your own risk.
                  </li>
                  <li class="rb-page--list__a increment">
                    Girls or women may be married, as indicated in the paragraph on marital status,
                    and most likely they are not looking for a life partner, but they are interested
                    in communicating and practicing foreign languages. Some of them may hide their
                    marital status. They betray their husbands and can hardly be desirable partners
                    for you in the future. Our team suggests to avoid such women. They can hide the
                    fact of marriage, as they are scammers and want to deceive you. Big social
                    networks for dating, such as “
                    <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
                    ”, value their reputation, verify the data of users to make sure that they are
                    adults in their country and that they are not married. Only single women can
                    become our users.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <p>
                  On the Internet, you can find many sites with "black lists" of scammers who
                  tricked men. On such sites, deceived men post photos of unscrupulous women and
                  complain about them. Some of these lists can be trusted, but it happens that
                  offended men in this way want to take revenge on those girls who did not
                  reciprocate or reject them. It happens that men pay for the services of anti-scam
                  sites so that information about such women is included in the “black list”.
                  Therefore, it is impossible to determine exactly who is really trustworthy, and in
                  this regard, “
                  <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
                  ” recommends not using similar sites.
                </p>
              </li>
              <li class="increment">
                <p>
                  Many social networks for dating and communication are doing everything possible to
                  protect their users from scamming. They thoroughly check the content (text and
                  multimedia), as well as other documents, but these efforts are not enough to
                  create a strong relationship between a man and a woman. None of the services will
                  ever give you a 100% guarantee that you are perfectly matched to each other. Your
                  relationship has a chance to grow into a happy marriage or instead of this to turn
                  into disappointment, regardless of whether they are real or virtual. No one is
                  insured from the bad person’s character, harmful habits and indelicate behavior of
                  their partners. But despite this, it is crucial for our website that our customers
                  feel protected from scamming. On the Internet, anyone can face the fact of
                  scamming, but no one wants to become its victim. The objectives and obligations of
                  the administration of our site are to protect the interests of our users in case
                  of any trouble associated with scammers.
                </p>
              </li>
              <li class="increment">
                <p>
                  In cases where you may become a victim of a scam as a result of using our website
                  and or its services, then at our own discretion, our company has the opportunity
                  to repay a part of credits or an equivalent amount of cash that you spent in
                  communication with a scammer. This is real if the fact of scam can be verified and
                  confirmed. In any case, such situations are very rarely found on our website, we
                  are sure of it. Our company cares and protects the safety of its customers. It is
                  very important for us that you find your happiness, your soul mate and love
                  through “
                  <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
                  ”. We do our best to make your search safe and fruitful.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    sex() {
      return this.$store.getters.sex;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    this.sex.isWoman
      ? (document.title = 'Политика анти-мошенничества')
      : (document.title = 'Anti-Scam Policy');
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
