<template>
  <div v-if="sex.isWoman" class="rb-page pr-pol">
    <div class="container feedback-wrap">
      <div class="white-box">
        <div class="row">
          <div class="col-xs-12">
            <h1 class="rb-page--header">Политика конфиденциальности</h1>
            <p>
              Независимо от того, что вы делаете при посещении веб-сайта, Вы всегда предостав-ляете
              информацию о своем посещении администрации текущего сервиса. Соответственно
              <router-link :to="{ name: 'index' }"> {{ siteName }} </router-link>
              (именуемое в дальнейшем «веб-сайт», «сервис», «веб-приложение») также не будет
              исклю-чением. Мы так же, как и Вы, ценим конфиденциальность, поэтому наша
              администрация любезно предлагает Вам прочитать информацию, которая поможет Вам принять
              самостоя-тельные решения о том, как управлять информацией о Вашей личной жизни.
            </p>
            <p>
              Политика конфиденциальности веб-сайта должна включать эту информацию, кото-рая затем
              должна быть открыта для общего использования. Вы можете свободно связаться с нами
              через форму обратной связи в случае, если по какой-либо причине Вы не смогли найти эту
              информацию на нашем веб-сайте.
            </p>
            <p>
              Сайт работает и использует только ту информацию, которую мы можем получить от Вашего
              посещения, мы делаем это только для наших внутренних целей, чтобы сделать {{ siteName }}
              ин-тересным, эффективным и качественным. Мы не имеем права использовать Вашу личную
              информацию для любых других целей.
            </p>
            <ol class="rb-page--list rb-page--list__main list-unstyled">
              <li class="increment">
                <h2>Личные данные, которые мы собираем.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Каждый раз, когда Вы посещаете {{ siteName }}, мы записываем доменное имя Интернет-
                      провай-дера, а также страну, из которой Вы пришли (например, «aol.com»), и
                      маршрут от одной страницы к другой (например, между вкладками веб-сайта).
                    </p>
                    <div class="rb-page--list__a increment">
                      <p>Регистрация посетителей и информационные запросы.</p>
                      <p>
                        {{ siteName }} собирает Ваши личные данные только в случаях Вашего добровольного
                        предоставления их для регистрации на {{ siteName }}. «Личная информация» включает
                        всю ин-формацию, которая может идентифицировать Вас как отдельное лицо,
                        например: Ваше имя, адрес электронной почты, а в случаях, когда мы хотим
                        отправить Вам дополнительные уве-домления, необходим контактный номер
                        телефона. Даже если у Вас нет регистрации на нашем сайте, Вы можете его
                        просматривать, но чтобы использовать определенные функции Вы должны на нем
                        зарегистрироваться.
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>Просмотр и использование файлов Cookies.</p>
                      <p>
                        {{ siteName }} использует технологию Cookies как часть статистической отчетности
                        нашего веб-сайта. Файлы Cookies представляют собой небольшие файлы, которые
                        хранятся на ком-пьютере пользователя. Файлы Cookies содержат данные,
                        необходимые для персонализации Вас и Ваших действий на веб-сайте, ускоряют
                        загрузку страниц сайта, таких как изображе-ния на главной странице {{ siteName }},
                        и для сбора статистических данных веб-сайта. Эти данные состоят из страниц,
                        размещенных на веб-сайте, загруженного контента, имени домена по-ставщика
                        услуг Интернета (например, «aol.com» или «umich.edu»), страны, из которой
                        при-ходят наши посетители, и адреса сайтов, которые они посетили до и после
                        перехода на {{ siteName }}. Cookies позволяют нам отслеживать активность Ваших
                        «кликов» (то есть способ Ваших предыдущих посещений сайта, как вы переходите
                        с одной страницы на другую. Cookies не предоставляют нам никаких сведений о
                        Вашем адресе электронной почты или любой личной информации). Эта информация
                        отправляется и анализируется только в целом. Несмотря на это, это не
                        относится к Вам персонально.
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>Использование стандартных файлов журнала веб-сервера.</p>
                      <p>
                        Файлы журналов используются для расчета количества посетителей и оценки
                        технических возможностей нашего сервиса. Такая информация помогает нам
                        размещать страницы в наиболее полезной форме для каждого пользователя,
                        разрабатывать удобный и всесторон-ний интерфейс веб-сайта, а также делать
                        наши страницы более полезными для наших посе-тителей. Вся информация о
                        трафике сайта анализируется и хранится нами, но мы никогда не используем
                        данные отдельных посетителей нашего сервиса, поэтому никакая информация о
                        Вас не будет сохранена или использована.
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>Просмотр без использования файлов Cookies.</p>
                      <p>
                        Вы можете точно настроить браузер, чтобы отключить использование всех файлов
                        Cookies или настроить уведомления об установке Cookies. (Каждый браузер
                        выполняет про-цедуру сохранения Cookies по-разному, поэтому, пожалуйста,
                        прочитайте эту информацию в меню «Справка» Вашего браузера).
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>Мы можем использовать эту информацию для:</p>
                      <ul class="ul-styled">
                        <li>
                          организация формы веб-сайта, которая наиболее подходит для каждого
                          пользователя;
                        </li>
                        <li>выполнение Ваших запросов на услуги и / или продукты сайта;</li>
                        <li>оценки Вашего опыта при использовании нашего веб-приложения;</li>
                        <li>
                          предоставления Вам специальных предложения и интересных новостей (если Вы
                          разре-шаете такие уведомления);
                        </li>
                        <li>
                          внесения изменений в Вашу учетную запись в соответствии с Вашими
                          предпочтениями;
                        </li>
                        <li>
                          совершенствования нашего сервиса и / или ассортимента его продуктов и
                          услуг;
                        </li>
                        <li>
                          обеспечения защиты от мошенничества и других нарушений, которые могут
                          возникать при использовании нашего веб-сайта;
                        </li>
                        <li>ответа на Ваши вопросы или предложения;</li>
                      </ul>
                    </div>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Электронная почта, списки рассылки и другие услуги.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Отправляя нам свой адрес электронной почты, мы, в свою очередь, используем
                      его, чтобы благодарить Вас за регистрацию на нашем веб-сайте или отправлять
                      другие уведомления, если Вы их не отключили), чтобы прояснить Ваши комментарии
                      и / или ответить на Ваши вопросы, для отправки текущей информации учетной
                      записи (логин и пароль во время ре-гистрации учетной записи или при смене
                      пароля). Мы обязаны хранить Ваши сообщения, а также наши ответы на них для
                      дальнейшей переписки.
                    </p>
                    <p class="rb-page--list__a increment">
                      Каждый день Вы можете получать по электронной почте определенную рекламную
                      инфор-мацию или новости, специальные предложения, которые соответствуют Вашим
                      интересам или уведомления об изменениях в компании. Нажав кнопку «Отменить
                      подписку», вы мо-жете отказаться от рассылки таких уведомлений, данная кнопка
                      размещена на фирменном бланке письма.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Ссылки на другие сайты.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Пожалуйста, обратите внимание, когда Вы используете ссылки для перехода с
                      {{ siteName }} на другой веб-сайт или службу, правила сайта и политика
                      конфиденциальности сайта больше не действительны. Ваше программное обеспечение
                      и то или иное сотрудниче-ство с другими сайтами, включая любые сайты,
                      предоставляющие ссылки на {{ siteName }}, регу-лируются правилами и политикой сайта,
                      который Вы посетили. Мы предлагаем Вам озна-комиться с правилами и условиями
                      сайтов, которые Вы посещаете, чтобы понять процедуры сбора, использования и
                      раскрытия Вашей личной информации.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>
                  Наша политика в отношении обмена информацией с партнерами и / или поставщиками.
                </h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Мы имеем право поделиться информацией со сторонними компаниями, которые
                      помогают нам поддерживать наш веб-сайт и / или поставлять продукты, а также
                      предоставлять услуги, которые мы предлагаем Вам. Мы можем сотрудничать с
                      внешними компаниями (список неполный) с целью:
                    </p>
                    <ul class="ul-styled">
                      <li>управлять базами данных и базами данных о клиентах (CRM-система);</li>
                      <li>иметь достаточное место для хранения данных и системы анализа данных;</li>
                      <li>выполнения поставки;</li>
                      <li>рассылки электронной почты;</li>
                      <li>
                        организации рекламных кампаний, маркетинга и кампании по сбору данных;
                      </li>
                      <li>предотвращения мошенничества;</li>
                      <li>
                        предоставлять другие услуги, которые могут помочь нам в развитии нашего
                        бизнес- по-тенциала.
                      </li>
                    </ul>
                    <p>
                      Мы добиваемся от всех сторонних компаний соблюдать статус конфиденциальности в
                      отношении всей информации, которую они получили от нас. В рамках наших
                      соглашений с ними наш сайт разрешает им использовать эту информацию только для
                      выполнения своих обязательств.
                    </p>
                    <p class="rb-page--list__a increment">
                      Сохранение личной информации в случае реорганизации компании
                    </p>
                    <p>
                      Мы можем предоставить Вашу личную информацию или предоставить ее копию, если
                      сайт {{ siteName }} решит продать часть своих активов, попадет под процедуру слияния
                      или приобретения другой компанией.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Соблюдение законодательства.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Мы предоставляем доступ к личной информации в случаях, предусмотренных
                      законом, что-бы сотрудничать с полицией или другими правоохранительными
                      органами для расследова-ния и / или другого судебного разбирательства в целях
                      защиты от неправильного использо-вания или несанкционированного использования
                      нашего веб-сайта для ограничения нашей юридической ответственности, а также
                      для защиты наших прав или сохранения прав соб-ственности или безопасности
                      участников этого сервиса или его посетителей.
                    </p>
                    <p class="rb-page--list__a increment">
                      Компания {{ settings.company_name }} ни в коем случае, преднамеренно или
                      намеренно, за исключением случаев, предусмотренных в настоящем положении, не
                      передает и не раскрывает личную информацию своих участников третьим лицам. Все
                      сотрудники компании подписывают соглашение о коммерческой тайне и несут полную
                      ответственность за несанкционирован-ное раскрытие личных контактов
                      пользователей веб-сайта и / или его услуг.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Возрастные ограничения.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Всем пользователям запрещается пользоваться нашим сайтом и / или его услугами,
                      если им не исполнилось восемнадцать лет. Наш сервис и / или его услуги
                      разрабатываются для ауди-тории старше 18 лет, и в соответствии с Законом о
                      защите детей в Интернете любая инфор-мация, которую мы можем получить от
                      пользователей, которых мы считаем несовершенно-летними, будет удалена из нашей
                      базы данных.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Доступ, обновление и / или изменение информации.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Если Вы хотите изменить или удалить свою личную информацию с веб-сайта,
                      следуйте следующим инструкциям:
                    </p>
                    <ul class="ul-styled">
                      <li>
                        Используйте пароль, чтобы войти в свою учетную запись. {{ siteName }} не сохраняет
                        списки или информацию о паролях пользователей (когда пароль нужно
                        восстановить, он приобре-тает статус «отклонен», а затем заменяется новым
                        паролем, который Вы выберите);
                      </li>
                      <li>
                        Выберите «Настройки» в меню управления учетными записями в шапке сайта
                        {{ siteName }};
                      </li>
                      <li>Измените свою личную информацию в разделе «Общие»;</li>
                      <li>Переключите настройки уведомлений в «Уведомления»</li>
                      <li>Нажмите «Сохранить» в «Общее».</li>
                    </ul>
                    <p>
                      Используя форму заявки, Вы также можете отправить запрос нашим сотрудникам, и
                      мы можем выполнить необходимые изменения. Удаление Вашего профиля и изменение
                      адреса электронной почты возможно только через форму обращения.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Внутренняя безопасность.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Для нас чрезвычайно важно защитить Вашу личную информацию, и мы всегда готовы
                      за-щитить данные, которые мы собираем. {{ siteName }} использует брандмауэры и имеет
                      все средства безопасности на своем оборудовании и физических объектах для
                      защиты от потери, неправильного использования и / или несанкционированного
                      изменения информации, которую мы получили от Вас, или на нашем веб-сайте и /
                      или ее услугах.
                    </p>
                    <p class="rb-page--list__a increment">
                      {{ siteName }} имеет сертификаты VeriSign и McAfee, эти две ведущие системы
                      безопасности в полной мере сертифицированы, одобрены и протестированы на сайте
                      и / или его службах.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Обслуживание клиентов и связь с сайтом.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Обращаем Ваше внимание на то, что когда Вы предоставляете нашему веб-сайту
                      любую информацию о использовании веб-сайта и / или его услуг, Вы разрешаете
                      {{ siteName }} осуществлять обработку и использование (в пределах диапазона этого
                      документа) этой информации в пределах области политики обслуживания. Вы можете
                      связаться с нами, если у Вас есть какие-либо комментарии, вопросы или жалобы
                      относительно Политики конфиденциальности {{ siteName }} и / или как это влияет на
                      Вас, свяжитесь с нами через форму обращения или любым удобным для вас
                      способом.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Принятие Политики конфиденциальности.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      Настоящая Политика конфиденциальности, а также другие соглашения и правила
                      компании регулируют использование нашего веб-сайта и / или его услуг Вами,
                      включая любые споры в отношении конфиденциальности. После того, как Вы станете
                      пользователем нашего веб-приложения и / или его услуг, Вы принимаете правила,
                      установленные настоящей Политикой конфиденциальности.
                    </p>
                    <p class="rb-page--list__a increment">
                      В любое время {{ siteName }} сохраняет за собой право изменять, совершенствовать или
                      пересматривать эту Политику. Периодически мы будем пересматривать нашу
                      Политику конфиденциальности и публиковать эти улучшения на этой странице.
                      Пожалуйста, добавьте текущий документ в список избранного Вашего браузера,
                      чтобы наблюдать за изменениями в этом документе.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Дата вступления в силу.</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p>
                      В последний раз данное положение о конфиденциальности пересматривалось 18 мая
                      2017 года.
                    </p>
                    <p>Зарегистрированный адрес: {{ settings.company_address }}</p>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import { LOAD_PROFILE } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
  },
  mixins: [LOAD_PROFILE],
  data() {
    return {};
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  watch: {
    sex() {
      this.actions();
    },
  },
  mounted() {
    if (!window.localStorage['access-token']) this.$router.push({ name: '404' });
  },
  methods: {
    actions() {
      if (this.sex.isWoman) {
        document.title = 'Политика конфиденциальности';
      } else {
        this.$router.push({ name: '404' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
