<template>
  <div v-if="sex.isWoman" class="rb-page">
    <div class="container feedback-wrap">
      <div class="white-box">
        <div class="row">
          <div class="col-12">
            <h1 class="rb-page--header">Соглашение участницы</h1>
            <license-text :full="true" />
          </div>
        </div>
      </div>
    </div>
    <toasts />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import licenseText from './parts/wmnLicenseText.vue';
import { LOAD_PROFILE } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    licenseText,
  },
  mixins: [LOAD_PROFILE],
  data() {
    return {};
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    sex() {
      this.actions();
    },
  },
  mounted() {
    if (!window.localStorage['access-token']) this.$router.push({ name: '404' });
  },
  methods: {
    actions() {
      if (this.sex.isWoman) {
        document.title = 'Пользовательское соглашение';
      } else {
        this.$router.push({ name: '404' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.pr-pol {
  $list-padding: 0.6rem;

  &--header {
    margin-bottom: 30px;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
  }

  &--main {
    margin-top: 30px;

    > li {
      &:before {
        font-size: 22px;
        line-height: 1;
      }
    }
  }

  &--list {
    &__main {
      margin-top: 15px;
      h2 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      > li {
        &:before {
          font-size: 15px;
          content: counters(item, '.') '. ';
        }

        + li {
          margin-top: 15px;
        }
      }
    }
    &__q {
      font-weight: bold;
      margin: 10px 0;
    }
  }

  .ul-styled {
    padding: 0;
    list-style-type: none;
    > li {
      position: relative;
      padding-left: 20px;
      &:before {
        content: '\f178';
        font-family: 'FontAwesome';
        position: absolute;
        top: 0;
        left: 0;
        color: $brand-primary;
      }
    }
  }

  .increment {
    display: table;
    counter-increment: item;

    &:before {
      display: table-cell;
      padding-right: $list-padding;
      font-weight: bold;
    }

    .pr-pol--list__a {
      position: relative;
      display: table;
      margin-top: 10px;

      &:before {
        content: counters(item, '.') ' ';
        display: table-cell;
        padding-right: $list-padding;
        font-weight: bold;
      }
    }
  }

  .index-navbar,
  .user-navbar {
    position: static;
  }

  .relative {
    position: relative;
    top: 0;
  }

  .white-box {
    padding: 60px;

    a {
      color: $brand-primary;
    }

    p + p {
      margin-top: 1rem;
    }
  }
}
p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
