<template>
  <div class="rb-page terms">
    <div class="container feedback-wrap">
      <div class="white-box">
        <div class="row">
          <div
            :class="{ unselectable: isGuest }"
            class="col-12"
            @contextmenu="interact"
            @copy="interact"
            @cut="interact"
          >
            <h1 class="rb-page--header">Terms of Use Agreement</h1>
            <p>
              The terms of the user agreement and any disputes or claims, arising out of or in
              connection with them or their subject matter (including disputes and claims beyond the
              scope of this agreement), are governed and interpreted in accordance with the laws of
              the company country of {{ siteName }}, see 2.1. In case of any discrepancy between the
              translation of the agreement and the original in English, the English text shall
              prevail.
            </p>

            <ol class="rb-page--list rb-page--list__main rb-page--main">
              <li class="increment">
                <h2>General provisions</h2>
                <p class="text-uppercase">
                  IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, ABSTAIN FROM
                  THE USE OF OUR WEB APPLICATION AND APPLY FOR REMOVING YOUR ACCOUNT.
                </p>
                <ol class="rb-page--list rb-page--list__sub">
                  <li class="rb-page--list__a increment">
                    <router-link :to="{ name: 'index' }"> www.redbini.com </router-link>
                    is operated by {{ settings.company_name }} with the registered address:
                    {{ settings.company_address }}, under the laws of
                    {{ settings.company_country || 'Hungary' }}.
                  </li>
                  <li class="rb-page--list__a increment">
                    “{{ siteName }}” is the social network in the web application form. The purpose of
                    “{{ siteName }}” is to create the space, in which people can get acquainted,
                    communicate, meet, share their personal content and information. “{{ siteName }}” is
                    located in the Internet at address “
                    <router-link :to="{ name: 'index' }"> www.redbini.com </router-link>
                    ” (hereinafter – “web website”, “service”, “web application”). Use of “{{ siteName }}”
                    implies, that the communication is to be cheerful, thus it is essential for us
                    and You that our network remains the safe and friendly place. Thus, you agree to
                    use “{{ siteName }}” and avoid violating the terms and conditions of this agreement.
                  </li>
                  <li class="rb-page--list__a increment">
                    If you are concerned about how “{{ siteName }}” uses your personal information, please
                    read our Privacy Policy, which describes how we process your personal
                    information and protect your privacy when you use “{{ siteName }}”. By using “{{ siteName }}”,
                    you agree that we may use and process your data in accordance with our Privacy
                    Policy.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>The right to become the user of “{{ siteName }}”</h2>
                <p class="rb-page--list__q">Parties between whom this agreement is concluded</p>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    This Agreement represents a legally binding agreement between You (the "User" or
                    "Participant") and
                    {{ settings.company_name }} - Company with a legal address:
                    {{ settings.company_address }} (Hereinafter - “we" or "Company" or “{{ siteName }}”)
                    <h4>To become a user of our service it is necessary:</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    To become a user of our web application, you should find a partner of this
                    service (CPA partner), meet the requirements of the service and have the desire
                    to register the profile.
                  </li>
                  <li class="rb-page--list__a increment">
                    You can register on the website and use its services and other applications of
                    “{{ siteName }}”
                    <strong
                      >only if you are already 18 years old (or more, if another age of majority is
                      established in your country of residence)</strong
                    >. You must have all the relevant documents necessary to confirm this fact at
                    the time of registration.
                  </li>
                  <li class="rb-page--list__a increment">
                    By accepting this agreement, you confirm that you have the right and the ability
                    to bind yourself by the obligation to comply with the terms and conditions of
                    this agreement. Using “{{ siteName }}”, You agree not to violate the provisions of
                    international law and the laws of your country, as well as this agreement. You
                    are solely responsible for compliance with all existing local laws and
                    regulations, as well as this agreement.
                  </li>
                  <li class="rb-page--list__a increment">
                    By accepting this agreement, you also confirm that you have never been found
                    guilty by the court of scam, financial fraud, socially dangerous assault,
                    physical harm or sexual harassment, in sexual crimes, you also confirm, that you
                    are not a criminal, prosecuted for crimes of sexual nature, are not convicted of
                    any crime of moral debauchery, and you are not being prosecuted due to the above
                    specified crimes.
                  </li>
                  <li class="rb-page--list__a increment">
                    If we find out that you can not be a member of the “{{ siteName }}” service, your
                    account will be deleted, and to your representative (our CPA Partner) penalties
                    will be applied.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Term of the agreement validity</h2>
                <p>Entry into force of the present agreement and its termination</p>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    This agreement becomes valid from the moment when You first enter “{{ siteName }}”
                    web-site, regardless of your registration, so please read it carefully. By
                    taking any actions (including entering, using, registering or receiving
                    services) on the website and services from “{{ siteName }}”, you accept and agree not
                    to violate the terms and conditions of this agreement.
                  </li>
                  <li class="rb-page--list__a increment">
                    This agreement is valid for the entire period of using the “{{ siteName }}” web site
                    and its services, until the completion of all your actions on this resource, or
                    the termination of your account (suspension or removal).
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Using the web application and the content handling rules.</h2>
                <p>Possibility to have accounts in other social networks</p>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    You have the right to use other social networks with the following rule: since
                    having an account on “{{ siteName }}”, You must monitor Your status, photos and other
                    materials in other social networks that could remain after your previous
                    relationship or last marriage. Otherwise, we will take this as the deception on
                    Your part in relation to our service, which will entail the sanctions described
                    in sec. 4.7.
                    <h4>Publishing the materials on “{{ siteName }}”</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    On “{{ siteName }}” You are able to post any materials, including infographics,
                    photographs, videos, texts and other materials (hereinafter "Content"), which
                    are not subject to third-party copyrights. The posted materials should not
                    contradict the terms of this agreement, the norms of international and local
                    legislation. <br />
                    Rules to define the acceptability of materials. You must not upload or send the
                    content, which:
                    <ul class="ul-styled">
                      <li>
                        contains obscene language or insults, which can offend the interlocutors of
                        your clients and the administration of the service;
                      </li>
                      <li>is obscene, pornographic or otherwise insulting another person;</li>
                      <li>
                        is offensive, degrading or threatening, or encourages the racism, sexism,
                        hatred and intolerance;
                      </li>
                      <li>
                        calls for illegal activities and illegal actions, including terrorism, race
                        hatred, extortion of material goods and financial resources, or the
                        publication of such materials, which is the criminal offense;
                      </li>
                      <li>is slanderous;</li>
                      <li>
                        related to the commercial activities (including, without limitation, sales,
                        advertising, links to other sites or paid phone numbers) or is spam;
                      </li>
                      <li>is related to a commercial or other kind of secret;</li>
                      <li>
                        contains source codes or links to spyware, adware, viruses, corrupted files,
                        worm programs or other malicious code designed to disrupt, damage, restrict
                        or in any way interrupt the operation of any software, hardware,
                        telecommunications, networks, servers; "Trojan horses" designed to damage,
                        intervene, unlawfully intercept or obtain any information (including
                        personal information), whether through “{{ siteName }}” or without its use;
                      </li>
                      <li>
                        material that, in itself, or the fact of placement of which violates the
                        rights of a third party (including, without limitation, intellectual rights
                        and confidentiality);
                      </li>
                      <li>
                        is an image of another person if the Content was created/distributed without
                        the consent of that person.
                      </li>
                    </ul>
                    <h4>Rules regarding the Content of Your personal information</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You are not allowed to publish or distribute (in any way) anybody's personal,
                    contact or bank information on your page hosted on our service (the "Profile"),
                    or in any other place on our service (for example: names, home and postal
                    addresses, phone numbers, e-mail addresses, URLs, bank card numbers).
                    <br /><br />

                    <p>
                      Your profile must contain only true information in accordance with all
                      sections and graphs present in the form for filling in the application for
                      registration or placement of content on our service.
                    </p>

                    <p>
                      On the website “{{ siteName }}” you are personally forbidden, bypassing the rules
                      existing on the service, to disclose your personal contact information to the
                      other party of opposite sex To indicate and/or disclose personal information
                      to other users (personal e-mail address, contact phone number and/or other
                      information) and/or any other information about yourself is possible only on
                      the basis of a request and actual video-consent. Video-consent must be with
                      your presence in the camera frame (the person should be seen clearly) with an
                      identity document (the pages must clearly show the name, first name and
                      patronymic, your age, serial number of the document), on the basis of
                      international legislation and local Laws on the use of personal information.
                      The video confirmation must be uploaded to the service by your representative
                      (our CPA partner) and must be verified by our moderator.
                    </p>

                    <p>
                      We have the right to disclose your personal information to a third party in
                      the event that we receive a complaint from him that the Content uploaded by
                      you into our web application violates the copyright of a third party, its
                      right to confidentiality or any other legislative act.
                    </p>

                    <h4>Use of personal information of other users</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You are not allowed to use the personal, contact, banking or any other
                    information of other “{{ siteName }}” users, even if this information was transferred
                    to you on their free will. You are not allowed to use any information about
                    other users, which is beyond the information sufficient for acquaintance and
                    maintaining a dialogue on our service.
                    <br /><br />

                    <p>
                      The use of such information for commercial purposes, for sending spam,
                      threats, extortion, harassment, blackmail or other illegal purposes is
                      excluded.
                    </p>

                    <h4>
                      Providing information, such as login and password, to other people to log into
                      your account under your name
                    </h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    Only You have the right to access Your account on “{{ siteName }}”. You must not
                    transfer your login and password to anyone, as this compromises the security of
                    your personal information and Content uploaded by you to “{{ siteName }}”. You are
                    responsible for the safety of your login and password.
                    <br /><br />

                    <p>
                      In case of such violation, “{{ siteName }}” is not responsible for unauthorized
                      access to Your profile. Unauthorized access to the account leads to its full
                      and irreversible deactivation.
                    </p>

                    <p>
                      If You suspect that someone has access Your account, You should immediately
                      inform us or your representative (our CPA partner) about this through a
                      feedback form or in other ways. Also, You have to change Your login and
                      password.
                    </p>

                    <p>
                      “{{ siteName }}” reserves the right to delete Your account if the rules of login and
                      password are violated by You.
                    </p>

                    <h4>The ability to see Your content published on “{{ siteName }}”</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    When You upload Content to Your profile or the site “{{ siteName }}”, it can be seen by
                    all male users. If You do not want other users to see Your Content, do not post
                    it ton “{{ siteName }}”. If someone, based on Your personal information and/or personal
                    content, created an account at our service, You have the right to inform our
                    service about it through feedback with the current and dated video-message,
                    attached to Your message (with the identity document presented in the camera
                    frame), and we will deactivate this account within 14 calendar days.
                    <br /><br />

                    <p>
                      We reserve the right, without or with notice, to delete, edit, restrict or
                      block access to Your account, and also to perform these actions with respect
                      to content placed in Your profile, if we have reasonable grounds to believe
                      that Your profile contains content that violates the provisions of this
                      agreement or international law.
                    </p>

                    <p>
                      We reserve the right to publish Your content with or without notice to
                      advertise our service and third-party services (except for private photos,
                      e-mail addresses, contact phone number and passport data), in the public part
                      of our service and in mailing letters to male users, as well as on landings or
                      advertising banners, or sliders.
                    </p>

                    <p>
                      We have the right to verify Your Content and information (including personal
                      data) posted on “{{ siteName }}” for authenticity and validity, by verifying the
                      email address, contact phone number and uploaded video-confirmation (subject
                      to the requirements described in 4.2 and 4.3), for compliance with this
                      agreement and international or local law.
                    </p>

                    <p>
                      Moreover, we have the right to track Your personal Content and information
                      (including personal data) for the entire period of Your profile's existence on
                      our service, and also to track how and for what purpose You use “{{ siteName }}”.
                    </p>

                    <h4>Liability for violations of the rules for material placement</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    We are not responsible for the materials, personal data or any other information
                    that You post on “{{ siteName }}” through the site or its services in person or in the
                    case when these actions were performed by another person from Your account.
                    <br /><br />
                    <p>
                      Please use common sense when placing the Content on “{{ siteName }}”, as You are
                      solely responsible for it. Otherwise, Your account will be permanently
                      blocked, penalties will be imposed on Your representative (our CPA partner),
                      and materials about illegal activities will be forwarded to Interpol and law
                      enforcement agencies of Your country for further investigation.
                    </p>
                  </li>
                  <li class="rb-page--list__a increment">
                    In order to use “{{ siteName }}”, You can log in using your Facebook login. If You log
                    in to the system in this way, You provide us with access and permission to use
                    certain information on Your Facebook account, in particular, Your public
                    Facebook profile and information about friends on Facebook that are shared with
                    other “{{ siteName }}” users. For more information about the data we received from You,
                    as well as how they are used, see our Privacy Policy.
                    <br /><br />
                    <p>
                      You are responsible for maintaining the confidentiality of Your credentials
                      that You use to register with “{{ siteName }}”. In doing so, You are also solely
                      responsible for all actions that occur with the use of these accounting data.
                      If You think that someone has gained access to Your account, immediately write
                      to {{ supportEmail }}
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Property rights to the Content</h2>
                <h4>Property rights to the Content after it is uploaded on “{{ siteName }}”</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    You do not lose the right to the Content uploaded by You, provided that you are
                    its copyright holder and you can approve it. <br /><br />
                    <p>
                      Please note that when uploading the Content to “{{ siteName }}”, You thereby confirm
                      that You have the right to do so. You automatically transfer to us a
                      non-exclusive, royalty-free, permanent, global license to use this Content in
                      any way (including, without limitation, editing, copying, altering,
                      translating, reformatting, using in other materials, manufacturing derivative
                      materials, advertising, distribution and publication of Content for a wide
                      range of users of the opposite sex, in whole or in part, in any format and on
                      any medium that already exists or will be developed in the future, excluding
                      the passport data of you as a citizen of the country, private photos and
                      e-mail address, as well as personal contact phone number).
                    </p>
                    <p>
                      We can transfer the above license to affiliated companies and assignees
                      without any agreement with you.
                    </p>
                    <p>
                      By uploading the Content to “{{ siteName }}”, you acknowledge that you are the
                      exclusive author and owner of this Content and agree to waive the author's
                      moral rights to this Content (including without limitation the right to be
                      further considered an author).
                    </p>

                    <h4>Ownership of all other Content on “{{ siteName }}” website</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    All text, graphics, the user interface, trademarks, logos, sounds and decorating
                    are property of “{{ siteName }}” web site, are controlled or are licensed by us,
                    therefore are protected by the property right, the trademark and other
                    intellectual property rights.
                    <h4>Use of the Content not belonging to You</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You can not use the Content of “{{ siteName }}”, that you do not have any rights to.
                    You agree that by Your actions You do not violate the rights of third parties.
                    It means that You will not copy, change, distribute, publish or sell Content of
                    “{{ siteName }}” (except your own). In case of violating section 5 of this agreement,
                    You bear responsibility according to section 4.7.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>The Information we collect</h2>
                <p>
                  Obviously, we are not able to help You make new acquaintances if we do not have
                  some information about You, such as basic profile data and characteristics of
                  people with whom You want to meet. We have the right to collect information that
                  is generated when you use our services, for example, access logs. If You want to
                  get additional information, we will tell about it more detail below.
                </p>
                <h4>The Information You provide us with</h4>
                <p>
                  When using our services, You provide us with certain information. It includes the
                  following:
                </p>

                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    When You create an account, You provide us with your username and password, as
                    well as basic information necessary for the service: your gender, date of birth,
                    a description of Your character, information about lifestyle, interests and
                    other information, as well as photos and video. In order to verify Your account
                    and confirm the age of majority, as well as the fact that You are not married at
                    the moment, You provide us with a scan of Your civil passport pages, Your phone
                    number, e-mail address, and video- confirmation of Your desire to register on
                    the service “{{ siteName }}”. To add specific content, such as images or videos, You
                    can give us access to Your camera or photo album. Some of the information You
                    can give us is considered special or confidential in some countries, for
                    example, Your racial or ethnic origin, sexual orientation and religious beliefs.
                    By submitting this information to us, You agree to its processing by us.
                  </li>
                  <li class="rb-page--list__a increment">
                    During participation in our contests or events, our site collects information
                    provided by You during registration or upon entering the system.
                  </li>
                  <li class="rb-page--list__a increment">
                    When You contact our customer support center, we collect the information You
                    provide to us during chats and conversation. Sometimes we record or track
                    conversations for educational purposes and in order to provide high quality
                    services.
                  </li>
                  <li class="rb-page--list__a increment">
                    If You have a desire to contact someone outside the service, as well as to send
                    Your e-mail to the person who requested it from You, we will collect Your
                    information and fulfill Your request.
                  </li>
                  <li class="rb-page--list__a increment">
                    To ensure the functioning of the services, we also process Your communication in
                    a chat with other users and the Content that You publish.
                  </li>
                </ol>
                <h4>The Information We Receive From Other Persons</h4>
                <p>
                  In addition to the information that You provide to us directly, we also receive
                  information about You from others, including:
                </p>
                <ol class="reset">
                  <li>
                    Other users. Other users may provide information about You when they use our
                    services. For example, we may collect information about You from other users, if
                    they contact us to provide it.
                  </li>
                  <li>
                    Other partners. We may receive information about You from our partners, for
                    example, the one that is published on partner sites and platforms (in this case,
                    they can transmit information about the success of an advertising campaign).
                  </li>
                </ol>
                <h4>Information collected when using our services</h4>
                <ol class="reset">
                  <li>
                    <strong>Usage information</strong>. We collect information about Your usage of
                    our services, for example, how You use it (date and time of login, functions You
                    use, search, clicks and pages that are displayed for You, addresses of pages
                    from which You were redirected, the advertisement You click on and how You
                    communicate with other users (for example, the users with whom You communicate
                    and interact, the time and date of Your contact, the number of messages You
                    receive and send).
                  </li>
                  <li>
                    <strong>Information about the device</strong>. We collect information from Your
                    devices with the help of which You use our services, including: information
                    about hardware and software, such as IP address, device identifier and its type,
                    browser type, version and language, operating system, time zones, identifiers
                    related to cookies or other technologies that can identify your personal device
                    or browser (for example, IMEI / UDID and MAC address);
                  </li>
                  <li>
                    Information about Your wireless or mobile Internet connection, such as Your
                    service provider and signal strength;
                  </li>
                  <li>
                    Information about the sensors of Your device, such as accelerometers, gyroscopes
                    and compasses.
                  </li>
                  <li>
                    <strong>Other information provided with Your Consent</strong>. With Your own
                    permission, we may collect information about Your exact location (latitude and
                    longitude) using various means, depending on which services and device You use,
                    including GPS, Bluetooth or Wi-Fi connections. Your location may be collected in
                    the background, even when You are not using our services, if You have given us
                    permission to collect this information. If You do not give us permission to
                    receive information about Your location, we will not collect it. Similarly, with
                    Your Consent, we can collect Your photos and videos (for example, if you want to
                    publish a photo, video, or live broadcast on one of our services).
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>The main services of your account in the web application “{{ siteName }}”</h2>
                <h4>Gifts receiving at “{{ siteName }}”</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    Gift section. “{{ siteName }}” gives You the chance to receive gifts from interlocutors
                    of the opposite sex through “{{ siteName }}” service.
                    <h4>Gifts resending to another user of “{{ siteName }}”</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You may not resend gifts received through “{{ siteName }}” from male users to other
                    users. <br /><br />
                    Confirmation period for intention of receiving a gift sent via “{{ siteName }}” by a
                    user of the opposite sex. You have 5 days to confirm or cancel the application
                    for a gift from the courier in Your region/country, sent by Your interlocutor
                    through “{{ siteName }}”. <br /><br />

                    After confirmation, Your gift will be delivered within 5 calendar days and
                    transferred by Your representative in Your region, which on the basis of the CPA
                    partnership agreement represents our service in Your country/region and
                    supervises Your account on “{{ siteName }}”

                    <h4>Correspondence Addressees</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You are free to write to any registered user of the opposite sex (regardless of
                    whether he has online status) a letter of at least 200 and no more than 5000
                    characters, only in English. You can attach to a letter no more than 3 pieces of
                    Content (photos and videos from Your public and private gallery). Tt is
                    forbidden to send any links, addresses of third-party sites, e-mail addresses,
                    contact phone numbers in letters It is also prohibited to use in the
                    correspondence any materials, including text that contradicts section 4 of this
                    agreement. You must reply to incoming letters within 48 hours from the moment
                    You receive the incoming message from the person You are talking to.

                    <h4>Chat availability on “{{ siteName }}”</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    “{{ siteName }}” provides text, video and private chats. The video chat can not be
                    started if You have no webcam or it is disconnected, and also if interlocutor
                    rejected the request for the video chat and passed into the text chat. You can
                    not independently switch or reject the request to a private chat, in case You
                    have an active video chat, Your interlocutor can change its status to private.
                    <h4>The ability to invite users to chat</h4>
                    <p>
                      You are free to invite all registered users of opposite sex, who are online.
                    </p>
                    <h4>Chatting with a user on “{{ siteName }}”</h4>
                    <p>
                      You need to find a suitable interlocutor among all registered users with the
                      online status and send him the first message through the chat room
                      (hereinafter “Invite"). Your interlocutor begins to communicate with you or
                      rejects Your invite. Your interlocutor can also send you an invite, which is
                      valid for 20 seconds.
                    </p>
                    <p>
                      The chat is interrupted after 60 seconds of inactivity in the active text or
                      video chat. Inactivity is the inactivity of both or at least of one of the
                      interlocutors, which time is more than 60 seconds after the response to the
                      last message. Private chat is interrupted only after your interlocutor decided
                      to finish it.
                    </p>
                    <h4>Necessary facilities for high-quality chat</h4>
                    <p>
                      Your computer and mobile device must meet the recommended system requirements
                      of “{{ siteName }}” and its services. Also, You need a good Internet connection
                      through a dedicated Internet network with a connection speed of at least 50
                      Mb/s and a ping of not more than 100ms, or a qualitative connection via a
                      mobile operator. It is also not recommended to use outdated web cameras with a
                      resolution of less than 720p.
                    </p>
                    <h4>Sharing Your personal contact information with male interlocutors</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    To share Your contact information, Your representative (our CPA partner) should
                    receive an application from Your interlocutor to receive Your contacts through
                    the partner administrative panel. After that Your representative will notify You
                    about it. In order Your interlocutor can send such a request, You must be in his
                    favorites, and he must be in Yours and have a certain time of communication
                    through correspondence and chat. <br /><br />
                    <p>
                      After You accept such a request, You must re-perform the actions described in
                      clause 4.3 of this agreement with respect to Your representative. After all
                      the procedures have been completed by You and Your representative, our service
                      will disclose contact information to him.
                    </p>
                  </li>
                  <li class="rb-page--list__a increment">
                    In case of section 7 provisions violation of this agreement, You are solely
                    responsible for Your actions. In this case, your account may be permanently or
                    temporarily blocked, Your representative may be penalized, and materials about
                    illegal activities can be transferred to Interpol and law enforcement agencies
                    of Your country for further investigation.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Access to the web application “{{ siteName }}”</h2>
                <h4>Guaranteed integrity and long-term of the service “{{ siteName }}”</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    Unfortunately, we can not give such guarantee, as from time to time we should
                    carry out repair work, so the web application can meet unexpected circumstances.
                    Therefore, the services of “{{ siteName }}” are provided “as is”. We do not give any
                    guarantees regarding the quality, accuracy, functionality, availability and
                    efficiency of “{{ siteName }}” and reserve the right to suspend, deactivate, supplement
                    or modify the functionality, design or services of “{{ siteName }}”, without warning
                    and occurrence of any obligations in relation to You.
                    <h4>Access to the service via mobile devices</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    You can easily login to our service with the help of Your mobile device. Your
                    task is to do everything that depends on You, to ensure Your access to “{{ siteName }}”
                    (including, but not limited to, payments to the Internet provider and mobile
                    phone provider, as well as any other payments related to access). <br /><br />
                    <p>
                      We are not responsible in cases where the functionality and / or appearance of
                      the “{{ siteName }}” services are violated, or limited, or displayed in part as a
                      result of Internet connection errors, accidents on the transmission lines, or
                      accidents at cellular towers, or as the result of other malfunctions of Your
                      equipment or equipment of Your providers, regardless of the device or
                      applications that You use or will use (existing or which will be created in
                      the future) to enter our services.
                    </p>
                    <p>
                      By connecting to “{{ siteName }}” or by agreeing to receive messages and
                      notifications from “{{ siteName }}” on Your mobile phone and/or any other device, you
                      are aware that your Internet provider or mobile phone provider may charge You
                      an additional fee. That is not our responsibility.
                    </p>
                    <h4>I did not log in, and certain content “{{ siteName }}” is inaccessible. Why?</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    <p>
                      Unregistered or unauthorized users can only see the public portion of the
                      Content. They do not have a profile and there is no way to upload Content.
                    </p>
                    <p>
                      We can sometimes modify or supplement publicly available materials at our own
                      discretion and without prior notice.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Termination of access.</h2>
                <h4>Blocking of Your Profile.</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    If we have reasonable grounds to believe that You have violated the provisions
                    of this agreement and/or the rules of international and/or local law, we reserve
                    the right at our own discretion, without incurring any obligation for You
                    (financial or other):
                    <ul class="ul-styled">
                      <li>
                        permanently or temporarily block Your account (if it exists), and we may
                        revoke Your right to use the “{{ siteName }}” and/or its services in the future;
                      </li>
                      <li>
                        use any operational, technological, legal and other means to ensure
                        compliance with the terms (including, without limitation, the blocking of
                        certain IP addresses).
                      </li>
                    </ul>
                    We will try (but we do not have to) to notify You if Your access to “{{ siteName }}”
                    and/or Your profile is subject to suspension or termination.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Account removal</h2>
                <h4>Deleting a profile from “{{ siteName }}” site</h4>
                <p>
                  You can easily delete Your profile at any time, in case if You are registered on
                  our website. For this You must provide an electronic copy of Your civil passport
                  and a video message requesting the removal of the profile from our service. In the
                  video message, you should keep Your civil passport open on page 2, and justify the
                  reason for the desired action. We will remove the account to the basket within 14
                  calendar days.
                </p>
                <p>
                  The above data should be sent via the feedback form, through the cabinet of Your
                  account or through the guest entry of “{{ siteName }}”. If You forgot Your login and
                  password for Your account, please specify the e-mail address and id number of Your
                  account on the “{{ siteName }}” service.
                </p>
                <p>
                  We save Your profile in case You decide to restore it. Many users deactivate their
                  accounts for temporary reasons and accordingly expect that we will save their
                  information by the time they return to “{{ siteName }}”. Therefore, You have the
                  opportunity, through an application, to restore the account and profile in full
                  for an indefinite period after they have been deactivated. After deactivating Your
                  account, we reserve the right to remove any Content uploaded by You to “{{ siteName }}”.
                  For more information, please read our Privacy Policy.
                </p>
                <p>
                  If You marry a user of this service or a man who is not registered on “{{ siteName }}”,
                  then You are obliged to inform our service about this and also provide an
                  electronic copy of the documents certifying this fact. In case You marry a user of
                  this service, You must provide photos and video Content from the wedding. After
                  that we will remove the account to the basket within 14 calendar days.
                </p>
                <p>
                  The Content that You uploaded to our service, except Your profile (for example,
                  comments or messages in correspondence with Your interlocutors), can be displayed
                  on the website after deactivating Your profile.
                </p>
              </li>
              <li class="increment">
                <h2>Abuse and complaints.</h2>
                <h4>Complaints about the terms of use violation on the site</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    You can contact us through the feedback page in order to report a violation or
                    to complain about Content placed on “{{ siteName }}”, and briefly state the essence of
                    the violation.
                    <br />
                    We always try to help in solving any problem that You may face when using our
                    service.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Privacy policy</h2>
                <p>
                  <strong
                    >IF YOU DO NOT AGREE WITH OUR PRIVACY POLICY, ABANDON THE USE OF THE SITE AND
                    SUBMIT YOUR APPLICATION FOR REMOVING YOUR ACCOUNT.</strong
                  >
                </p>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    We know that the use of someone else's personal data is a big problem for social
                    networking users, so we at “{{ siteName }}” developed this Privacy Policy ("Policy")
                    for You to know how we use personal information ("personal data" or "personal
                    information") of all users of the service. <br /><br />
                    <p>
                      This Policy is effective every time You use
                      <router-link :to="{ name: 'index' }"> www.redbini.com </router-link>
                      (“{{ siteName }}” or "browser application") and/or its services, and it acts in
                      conjunction with all the rules of this agreement. By using “{{ siteName }}” as a
                      user, You agree to the Privacy Policy and give permission for the collection,
                      storage, processing, use and distribution of personal information in
                      accordance with this Policy.
                    </p>
                    <p>
                      “{{ siteName }}” keeps the right to change the Privacy Policy at any time without
                      prior notice. We ask You periodically review this section in order to stay
                      informed of how we use Your information.
                    </p>
                  </li>
                  <li class="rb-page--list__a increment">
                    We can use any of Your information or materials that You publish on “{{ siteName }}”
                    for publicity purposes on the websites and/or applications of our partners. We
                    consider that it will allow us to improve our website and to enhance Your online
                    experience to satisfy Your requirements.
                  </li>
                  <li class="rb-page--list__a increment">
                    We use Your personal and other information as the user for settlement of
                    disputes, elimination of inaccuracies and ensuring compliance with provisions of
                    this partner agreement.
                  </li>
                  <li class="rb-page--list__a increment">
                    Remember that it is forbidden to use personal or other information about other
                    users of our services for illegal purposes, commercial purposes, spam,
                    blackmail, threats and insults.
                  </li>
                  <li class="rb-page--list__a increment">
                    We collect additional information on You as the user by allocation of cookies on
                    the computer or the mobile phone. Cookies is a piece of text, stored on a
                    computer or mobile device by Your web browser. Cookies store information about a
                    visit to a website or partner administrative panel, learn the preferences of
                    users at each visit to “{{ siteName }}” and provide the functionality of the website,
                    allowing us to maintain the existing functionality and develop improvements for
                    CPA partners and users of “{{ siteName }}”.
                  </li>
                  <li class="rb-page--list__a increment">
                    When You visit “{{ siteName }}”, we use cookies for session saving. Cookies with a
                    session are deleted when the browser is closed. We also store persistent cookies
                    (also known as "local storage devices") on users' computers, so that the
                    “{{ siteName }}” service remembers users and makes the user authorization process
                    faster and easier. We can use persistent cookies on Your mobile device, mainly
                    for security purposes, for example, to prevent fishing (actions to intercept
                    logins and passwords), for protection against scammers, unauthorized logging
                    attempts and to help You access in case when Your account was hacked. We do not
                    use the information after You log out.
                  </li>
                  <li class="rb-page--list__a increment">
                    In the settings of the browser or mobile device, You can block the saving of
                    cookies and the operation of local storage devices, but with such blocking You
                    are not able to fully use the functions of the “{{ siteName }}” services.
                  </li>
                  <li class="rb-page--list__a increment">
                    Your “{{ siteName }}” website user account, with all content, is not transferable, and
                    all rights to it will be revoked in the event of Your death.
                  </li>
                  <li class="rb-page--list__a increment">
                    You are solely responsible for violating the provisions of section 12 of this
                    agreement. In this case, Your account may be permanently or temporarily blocked,
                    Your representative may be penalized, and materials about illegal activities can
                    be transferred to Interpol and law enforcement agencies of Your country for
                    further investigation.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Links.</h2>
                <h4>Related links that are available in our web app “{{ siteName }}”</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    “{{ siteName }}” and/or its services may contain links to other sites, resources with
                    the ability to make purchases, provided by third parties. These links are
                    provided to Your reference. If You follow these links, You can be redirected to
                    third-party’s sites. Third-party sites have their own terms of service and
                    privacy policy, which may differ from ours. Displaying links to third-party
                    sites does not imply our support of third-party content, sites or resources.
                    <br /><br />
                    <p>
                      Please note that we have no control and no liability for the content of
                      third-party sites or their resources, including (but not limited to)
                      compliance by any third party with any applicable laws or regulations or
                      provisions of this agreement.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Warranty disclaimer and limitation of liability.</h2>
                <h4>Responsibility for non-compliance with the behavior rules on the site</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    If Your actions abuse other users, in this case You and only You are responsible
                    for the consequences. We disclaim liability for Your behavior and actions, or
                    the behavior and actions of any other user of “{{ siteName }}”, and we also disclaim
                    responsibility for the Content uploaded by You or any other users.
                    <h4>Responsibility of “{{ siteName }}” website and / or its services</h4>
                  </li>
                  <li class="rb-page--list__a increment">
                    Nothing in this agreement, regardless of other provisions, can be accepted to
                    hold our service liable for Your injury to any degree, including those that
                    could have caused death, as a result of Your careless actions and / or because
                    of inattention, when using and/or resulting from the use of the “{{ siteName }}” web
                    site and/or its services. We exclude any of our responsibilities under the
                    applicable international and/or local laws (regulations), which may result from
                    Your careless actions and / or inattention, use and/or use of the “{{ siteName }}”
                    website and/or its services.
                  </li>
                  <li class="rb-page--list__a increment">
                    In the maximum degree, stipulated by the legislation, “{{ siteName }}” excludes all
                    conditions, warranties, guarantees and other conditions which could be presumed
                    by legal acts, the general law or the right of justice, and any responsibility
                    for damages, suffered by You as a result of use “{{ siteName }}”, services of the
                    website, including responsibility in claims, fines, requirements, losses,
                    obligations, expenses of any character and the type: straight, indirect,
                    accidental, punitive or indirect losses (including negligence); impossibility of
                    use, data loss, the damage caused to the computer by influence electronic
                    viruses, loss of the income or profit, expenditure of office time, violation of
                    terms of the contract or claims of the third parties or other losses of any kind
                    and character even if “{{ siteName }}” was warned about the possibility of such damage
                    or losses arising from or in connection with use the website “{{ siteName }}” and/or
                    its services or use of the partner administrative panel. <br /><br />
                    This limitation of liability extends, but is not limited to, to the transfer any
                    devices or viruses, which can infect Your equipment, cause a malfunction of
                    mechanical or electrical equipment or communication lines, telephone equipment
                    or other communication problems (for example, you can not access the Internet),
                    unauthorized access, theft, body injures (except those that were caused by our
                    negligence), material damage, operator's mistakes, strikes or force majeure,
                    including but not limited to liability for loss of profits, loss of income or
                    contracts, loss of business, loss of expected savings, loss of reputation, loss
                    of data, loss of office time and any other loss or damage of any kind.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Warranty against damage</h2>
                <h4>Bringing to court for non-compliance with the behavior rules on the site</h4>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    If our service or company is litigated as a result of how You use the “{{ siteName }}”
                    web site or its services, we have the right to defend or settle the relevant
                    claim as we consider it necessary. If we ask this, you have fully cooperate with
                    us in accordance with the requirements of the relevant lawsuit.
                  </li>
                  <li class="rb-page--list__a increment">
                    You are agree to protect us and our service, our employees, directors, agents,
                    representatives, partners from any third party claims, damages (direct and/or
                    indirect), litigation, claims, losses, expenses (including reasonable attorney's
                    fees), incurred by us and arising as a result or in connection with Your actions
                    on “{{ siteName }}” and/or its services, or the Content that You uploaded to “{{ siteName }}”
                    and/or its services, or by Your behavior, other than as in accordance with the
                    provisions of this Agreement or in accordance with applicable law. “{{ siteName }}”
                    reserves the exclusive right to settle and pay any claims or the bases for the
                    claims which are turned against us without Your prior consent. If necessary, we
                    will be forced to recover from You the damage caused to us.
                  </li>
                  <li class="rb-page--list__a increment">
                    When violating the provisions of section 15 of this agreement, You are solely
                    responsible for Your actions. In this case, Your account may be permanently or
                    temporarily blocked, Your representative may be penalized, and materials about
                    illegal activities can be transferred to Interpol and law enforcement agencies
                    of Your country for further investigation.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Trade secret</h2>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    The content of this agreement, of each section and paragraph thereof, as well as
                    any information about this document, is included in the list of company
                    documents that are a trade secret. <br /><br />
                    <p>
                      The right to become familiarized with this document has been transferred only
                      to You.
                    </p>
                    <p>
                      You are forbidden to disclose these data to any other third party (both
                      individuals or legal entity), without voluntary consent of the authorized
                      representative of the “{{ siteName }}” company (which fact You will have to confirm
                      afterwards). You bear complete legal responsibility according to the existing
                      international and/or local legislation for violation of this provision.
                    </p>
                    <p>
                      In case that the authorized state law enforcement or judicial authorities will
                      require, in the prescribed form, to disclose any information regarding this
                      document from You, You are required to notify (through the feedback form on
                      the site or through telephone or through correspondence) the authorized
                      representative of “{{ siteName }}” and obtain his consent to these actions (the fact
                      of which You will have to confirm subsequently).
                    </p>
                    <p>
                      We warn that “{{ siteName }}” will be compelled to protect this provision by all
                      legal means, and may also permanently or temporarily block Your account. In
                      addition, the penalties may be imposed on Your representative.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Disputes.</h2>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    You acknowledge that all transactions involving Your profile on our web service
                    and applications, including the purchase of goods or services, occur
                    territorially in
                    {{ settings.company_address }} You agree that any disputes or claims, in any way
                    connected with Your visit or use of the website and/or its services, or
                    associated with the use of any products and/or services of the company
                    “{{ siteName }}”, You will first try to resolve these disputes informally by contacting
                    us. If the dispute is not resolved within 30 days after Your appeal, You or our
                    company must resolve any claims, related to these terms, services or web service
                    of our company, by appealing to the arbitration court, located territorially at
                    the location of our company. The decision of this court will be final and
                    binding, unless You have the right to assert Your claims in a small lawsuit
                    court.
                  </li>
                  <li class="rb-page--list__a increment">
                    Prohibition of group suits. You have the right to regulate the disputes with us
                    individually but You have no right to take any legal actions both joint or
                    group.
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Other terms</h2>
                <ol class="list-unstyled rb-page--list">
                  <li class="rb-page--list__a increment">
                    “{{ siteName }}” takes all possible measures for the general availability, reliability,
                    completeness of the information contained on the “{{ siteName }}” website and its
                    service, and also undertakes to provide timely information on an "as-is" basis.
                    “{{ siteName }}” does not give any direct or indirect guarantees and obligations in
                    relation to information which is contained on “{{ siteName }}”. You assume the
                    responsibility for using “{{ siteName }}” and its services, and any materials available
                    in the application at Your own risk. "{{ siteName }}" does not bear any responsibility
                    for the loss of data during the transfer and use, as well as for the information
                    of incorrect (Content) provided by users and/or partners. You assume the
                    responsibility for all possible precautions to avoid infecting computer with
                    viruses while using the “{{ siteName }}” web site and its services. <br /><br />
                    By this agreement, You accept that during the use of the application errors and
                    failures that can not be corrected can occur, and that “{{ siteName }}”, its services
                    or the server can be attacked by viruses, spyware, Trojans or other malicious
                    software. “{{ siteName }}” does not bear responsibility for damage of technical
                    equipment or the software, for any damage or harm of property character,
                    including the claim concerning compensation of any damage caused in connection
                    with any error, omission, virus, unauthorized access, fraud, cracking, delays in
                    operation of application. “{{ siteName }}” does not bear responsibility for any damage
                    caused in connection with any malfunctions on the Internet or any other
                    technical failures.
                  </li>
                  <li class="rb-page--list__a increment">
                    We reserve the right at own discretion and without any obligation to adjust and
                    make changes to this agreement at any time.
                    <br /><br />
                    <p>
                      All changes will be published on this page (at the end of this agreement) with
                      the date of entry into force indication. In some cases, we can e-mail You
                      about changes. You undertake to periodically review this page to avoid
                      misunderstanding due to eventual changes.
                    </p>
                    <p>
                      If you do not accept any of the changes to these terms in this agreement, you
                      must immediately stop using “{{ siteName }}” and apply for deactivation of your
                      account. If you continue to use the website “{{ siteName }}”, its services after
                      changes in this agreement, you automatically accept the updated conditions.
                    </p>
                  </li>
                  <li class="rb-page--list__a increment">
                    If, for any reason, any of the terms of this agreement is recognized by the
                    court of the relevant jurisdiction as unlawful or legally invalid, this
                    provision will be removed from the agreement or edited without any changes to
                    the other mandatory conditions and their legal effect.
                  </li>
                  <li class="rb-page--list__a increment">
                    These terms and conditions are an agreement between us and You as a user who
                    uses our information resource, and replace all (oral or written) previous
                    agreements, statements and agreements between us. This paragraph does not in any
                    way limit or exclude any liability for fraudulent misrepresentation or willful
                    misrepresentation of facts.
                  </li>
                  <li class="rb-page--list__a increment">
                    Non-performance or delay in implementation of any right, power or the privilege
                    according to this agreement is not the waiver of such right or acceptance of any
                    changes in provisions, and also partial implementation of any of the parties of
                    any right, power or the privilege does not exclude further implementation of
                    this right or implementation of any other right, power or the privilege.
                  </li>
                  <li class="rb-page--list__a increment">
                    Nothing in provisions provides or aims to grant any other third party the
                    benefit and the rights of providing any other agreement or other agreements (The
                    rights of the third parties).
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
    supportEmail() {
      return this.$store.getters.siteSettings.support_email
    },
  },
  mounted() {
    document.title = 'Terms of Use Agreement';
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

/*  .terms {
      .reset {
        counter-reset: list;
        padding: 15px 0;
        > li {
          &:before {
            counter-increment: list;
            content: counter(list) ". ";
          }
        }
      }

      ol {
        list-style-type: none;
        counter-reset: item;
        padding: 15px 0;
        > li {
          counter-increment: item;
          margin-bottom: 10px;
          &:before {
            content: counters(item, ".") ". ";
            padding-right: 0.6rem;
          }
        }
        ol > li {
          &:before {
            content: counters(item, ".") " ";
          }
        }

      }

      li > h4 {
        text-align: center;
        margin: 15px 0;
      }

      &--main {
        margin-top: 30px;

        > li {
          &:before {
            font-size: 22px;
            line-height: 1;
          }
        }
      }

      &--list {
        !*&__item + &__item {*!
        !*margin-top: 1rem;*!
        !*}*!
        &__item {
          !*padding-left: 45px;*!

          !*&:before {
                      left: 15px !important;
                  }*!

          !*&:nth-child(n + 9) {
                      + li {
                          padding-left: 50px;
                      }
                  }*!
        }
      }

      !*&--section + &--section {*!
      !*margin-top: 20px;*!
      !*}*!

      .index-navbar,
      .user-navbar {
        position: static;
      }

      .relative {
        position: relative;
        top: 0;
      }

      .white-box {
        padding: 60px;

        a {
          color: $brand-primary;
        }

        p + p {
          margin-top: 1rem;
        }

      }

      &--header {
        margin-bottom: 30px;
        &__sub {
          font-size: 15px;
        }

        &__sub + &__sub {
          margin-top: 1rem;
        }

        &__section {
          margin-bottom: 15px;
          font-size: 22px;
        }
      }
    }*/
p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
