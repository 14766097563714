<template>
  <div class="rb-page pr-pol">
    <div class="container">
      <div class="white-box">
        <div class="row">
          <div class="col-12">
            <h1 class="rb-page--header">Privacy Policy</h1>
          </div>
          <div
            class="col"
            :class="{ unselectable: isGuest }"
            @contextmenu="interact"
            @copy="interact"
            @cut="interact"
          >
            <div>
              <p>
                No matter what You do when visiting a website, You always provide information on
                Your visit to the administration of current service. {{ siteName }} (hereinafter referred
                to as “website”, “service”, “web application”), is not exceptional. We appreciate
                the privacy as much as You do, so our administration kindly proposes You to read
                information that will help You to make self-contained decisions on how to manage the
                information about Your private life.
              </p>
              <p>
                The website's Privacy Policy must include this information, which then should be
                opened to general use. You can freely contact us through the feedback form in case,
                when for any reason, You could not find this information on our website.
              </p>
              <p>
                The website operates and uses only the information that we are able to receive from
                Your visit, we do it only for our internal purposes, in order to make {{ siteName }}
                interesting, effective, and qualitative. We have no right to use Your personal
                information for any other purposes.
              </p>
              <ol class="rb-page--list rb-page--list__main list-unstyled">
                <li class="increment">
                  <h2>Personal data that we collect.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        Every time You visit {{ siteName }}, we record the domain name of the Internet
                        access provider as well as the country where You came from (for instance,
                        'aol.com') and the route from one page to another (for example, between the
                        website tabs).
                      </p>
                      <div class="rb-page--list__a increment">
                        <p>Registration of visitors and information requests.</p>
                        <p>
                          {{ siteName }} collects Your personal data only in cases of Your voluntary
                          provision of them for registration purpose on {{ siteName }}. "Personal
                          information" includes all information that may allow to identify You as an
                          individual person, such as: Your name, e-mail address, and, in cases we
                          want to send additional notifications to You, a contact phone number is
                          required. Even if You don’t have registration on our website You are able
                          to view site, but to use certain functions You should be registered here.
                        </p>
                      </div>
                      <p class="rb-page--list__a increment">
                        Website {{ siteName }} does not consider, use or keep information about Your credit
                        cards or any other payment systems. The company-supplier Multicards provides
                        You the payment service according to partnership agreements. In this case
                        the supplier bears full responsibility for the safety of these data. Note,
                        that credit card details will be handled securely to ensure confidentiality.
                      </p>
                      <div class="rb-page--list__a increment">
                        <p>Browsing and using cookies.</p>
                        <p>
                          {{ siteName }} adopts the Cookies technology as part of the statistical reporting
                          of our website. Cookies are small files which are stored on a user's
                          computer. Cookies contain the data, necessary for personalization of You
                          and Your actions on the website, accelerate the loading of the site pages,
                          such as images on the main page of {{ siteName }}, and for collecting the website
                          statistic data. These data consist of pages placed on the website, the
                          uploaded content, the Internet service provider domain name (for example,
                          'aol.com,' or 'umich.edu'), the country from which our visitors come, and
                          the addresses of sites they have visited before and after jumping to
                          {{ siteName }}. Cookies allow us to monitor Your "click" activity (i.e., the way
                          of our previous site visits, the way You move from one page to another.
                          Cookies does not provide any instructions of Your e-mail address or any
                          personal information). This information is sent and analyzed only in
                          general. In spite of this, this does not relate with You as a person.
                        </p>
                      </div>
                      <div class="rb-page--list__a increment">
                        <p>Using the standard web server log files.</p>
                        <p>
                          Log files are used to calculate the quantity of visitors and evaluate the
                          technical capabilities of our service. This kind of information assists us
                          in placing the pages in the most helpful form for every user, to develop
                          the user-friendly and comprehensive website interface, as well as to make
                          our pages more useful for our visitors. All information about the site
                          traffic is analyzed and stored by us, but we never use the data of
                          individual visitors of our service, therefore, no information about You
                          will be saved or used.
                        </p>
                      </div>
                      <div class="rb-page--list__a increment">
                        <p>Viewing without using cookies.</p>
                        <p>
                          You can fine tune Your browser in order to turn off the use of all cookies
                          or set up notifications on Cookies installation. (Every browser implements
                          the saving cookies procedure differently, so please read this information
                          in Your browser "Help" menu).
                        </p>
                      </div>
                      <div class="rb-page--list__a increment">
                        <p>We can use this information for:</p>
                        <ul class="ul-styled">
                          <li>
                            organization the website form, that is the most suitable for every user;
                          </li>
                          <li>implementation of Your requests for services and/or services;</li>
                          <li>estimation of Your experience while using our web application;</li>
                          <li>
                            suggesting You special offers and interesting news (if You enable such
                            notifications);
                          </li>
                          <li>making changes in Your account in accordance with Your choices;</li>
                          <li>
                            improving of our service and/or its range of products and services;
                          </li>
                          <li>
                            providing protection against fraud and other violations which may occur
                            while using our website;
                          </li>
                          <li>responding Your questions or proposals;</li>
                        </ul>
                      </div>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>E-mail, mailing lists and other services.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        By sending us Your email address, we, in our turn, use it to give thanks to
                        You for registering on our website or to send other notifications if You
                        have not turned them off), to clarify Your comments and/or respond Your
                        questions, to send the current account information (login and the password
                        at the time of registration of the account or while changing the password).
                        We are obliged to store Your messages as well as our answers to them for
                        further correspondence.
                      </p>
                      <p class="rb-page--list__a increment">
                        Every day You are able to receive by e-mail certain advertising information
                        or news, special offers which meet Your interests or notifications about
                        changes from the company. By clicking the “Unsubscribe" button You can
                        unsubscribe from these notifications, the button is placed on the
                        letterhead.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Links to other sites.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        Please pay Your attention, when You use the links to skip from {{ siteName }} to
                        another website or service, the site rules and site Privacy Policy are no
                        longer valid. Your software and any cooperation with any other website,
                        including any sites that provide links to {{ siteName }}, are regulated by the
                        rules and policies of the site You visited on. We suggest You to get
                        acquainted with the policies and rules of the sites that You visit in order
                        to understand the procedures for collecting, using and revealing Your
                        personal information. SSL encryption is employed to ensure sensitive data is
                        protected.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>
                    Our policy regarding the exchange of information with partners and/or suppliers.
                  </h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        We have the right to release the information to the third party companies
                        that help us in supporting our website and/or in delivering products as well
                        as providing the services that we offer to You. We can cooperate with an
                        external company (the list is not complete) with the purpose to:
                      </p>
                      <ul class="ul-styled">
                        <li>manage databases and customer information databases (CRM system);</li>
                        <li>
                          have enough place for data information storage and the data analysis
                          system;
                        </li>
                        <li>perform the deliveries;</li>
                        <li>spread e-mails;</li>
                        <li>
                          organize advertising campaigns, marketing and a campaign for data
                          collection;
                        </li>
                        <li>fraud prevention;</li>
                        <li>
                          provide other services that are helpful for us in the development of our
                          business potential.
                        </li>
                      </ul>
                      <p class="rb-page--list__a increment">
                        Preservation of personal information in case of the company reorganization
                      </p>
                      <p>
                        We can impart Your personal information or provide a copy of it, if website
                        {{ siteName }} decides to sell the part of its assets, goes through merger
                        procedures or acquisition by another company.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Law compliance.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        We are providing access to personal information in cases required by law in
                        order to cooperated with the police or other law-enforcement bodies for
                        investigation and/or other legal proceedings to protect against misapply or
                        unauthorized use of our website to limit our legal responsibility, as well
                        as to protect our rights or to preserve the property rights or safety of
                        this service participants or its visitors.
                      </p>
                      <p class="rb-page--list__a increment">
                        The company {{ settings.company_name }} in no case, deliberately or
                        intentionally, except as provided in the present provision, transfers or
                        reveals any personal information of its participants to third parties. All
                        company employees sign the trade secret agreement and are fully responsible
                        for unauthorized disclosure of personal contacts of users of the website
                        and/or its services.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Age restrictions.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        All users are prohibited to use our website and/or its services if they are
                        under the age of eighteen years. Our service and/or its services are
                        developed for an audience above 18 years old, and, in accordance with the
                        Children's Online Privacy Law, any information, that we can receive from
                        users, whom we consider underage, will be deleted from our database.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Access, information update and/or change.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        If you want to change or remove Your personal information from website,
                        please follow next instructions:
                      </p>
                      <ul class="ul-styled">
                        <li>
                          Log in to Your account, using Your password. {{ siteName }} does not store any
                          lists or any users password information (when password is recovered, it
                          becomes "denied" and then replaced with a new password that You selected);
                        </li>
                        <li>
                          Choose "Settings" in the account management menu in the headline of
                          {{ siteName }};
                        </li>
                        <li>Change Your personal information in the "General";</li>
                        <li>Switch the notification settings in the "Notifications"</li>
                        <li>Click "Save" in the "General".</li>
                      </ul>
                      <p>
                        By using the application form You can also send a request to our employees
                        and we could perform the required changes. To delete Your profile and to
                        change the e-mail address is possible only through the appeal form.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Internal security.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        It is extremely important for us to protect Your personal information, and
                        we always ready are to uphold the data we collect. {{ siteName }} uses firewalls
                        and has all means of security on its equipment and physical facilities to
                        protect against the loss, misuse and/or unauthorized alteration of the
                        information we collected from You, or on our website and/or its services.
                      </p>
                      <p class="rb-page--list__a increment">
                        {{ siteName }} has VeriSign and McAfee certificates, these two leading security
                        systems have certified, approved and tested the site and/or its services to
                        the fullest extent.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Customer service and communication with the site.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        Please, pay You attention, that when You provide our website any information
                        with regard to any use of the website and/or its services, You allow {{ siteName }}
                        to implement the handling and use (within the range of this document) of
                        this information within the area of the service policy. You are free to
                        contact us, if You have any comments, questions or complaints regarding the
                        Privacy Policy of {{ siteName }} and/or how it affects on You, please contact us
                        through the contact form or in any convenient way for You..
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Privacy Policy acceptance.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>
                        The present Privacy Policy and as well as other agreements and the company
                        regulations regulates the use of our website and/or its services by You,
                        including any disputes regarding confidentiality. Once You become a user of
                        our web application and/or its services, You accept the rules established by
                        this Privacy Policy.
                      </p>
                      <p class="rb-page--list__a increment">
                        At any time {{ siteName }} retains the right to change, modify or revise this
                        Policy. We will periodically enhance our Privacy Policy and publish these
                        improvements on this page. Please add current document to Your browser's
                        favorites list in order to observe the changes in this document.
                      </p>
                    </li>
                  </ol>
                </li>
                <li class="increment">
                  <h2>Effective Date.</h2>
                  <ol class="rb-page--list rb-page--list__sub list-unstyled">
                    <li class="rb-page--list__li">
                      <p>This confidentiality provision was last revised on May 18, 2017.</p>
                      <p>The registered address: {{ settings.company_address }}</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    document.title = 'Privacy Policy';
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';
@import '../../../assets/scss/vars';

p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
