<template>
  <div class="rb-page ref-p">
    <div
      class="container"
      :class="{ unselectable: isGuest }"
      @contextmenu="interact"
      @copy="interact"
      @cut="interact"
    >
      <div class="white-box">
        <div class="row">
          <div class="col-12">
            <h1 class="rb-page--header">Refund Policy</h1>
            <p>Refund Policy is the part of the Terms of Use of the Service.</p>
            <p>
              While purchasing the credit packages in order to use the service, the payment for the
              credits is performed through the processing service of MultiCards Online Payments,
              that, in its turn, provides this information to us. When You have purchased the credit
              package the internal currency of the service is transferred to Your account. After
              each time You use our chargeable services, the system holds its cost from Your
              personal credit account, but not from Your banking card. The relevant amount of
              credits is revoked from Your account every time You sent a letter, for each minute of
              being in an active chat, for gifts given by You to interlocutors and each time You
              request their contact information.
            </p>
            <p>At any time the cost of services can be varied.</p>
            <p>
              IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THE PRESENT POLICY, DO NOT CLICK
              THE "REGISTER" BUTTON OR, IF YOU ARE ALREADY THE SERVICE USER, STOP TO PARTICIPATE IN
              THE SERVICE!
            </p>
            <p>Every service user has the right to request repayment of their money or credits.</p>
            <p>
              <strong>IMPORTANT!</strong>
              Administration of website {{ siteName }} keeps the right to the eventual and conclusive
              decision on the crediting and/or refunding of cash and/or credits. With or without
              notice of the reasons for their decision the Administration may reject to reimburse
              and/or accrue the credits and/or money.
            </p>
            <p>
              Administration of the wedsite bears no responsibility for denying to refund and/or
              accrue the credits and/or money.
            </p>
            <p>
              All credits that have not been used by You for the period of 365 days from the date of
              their purchase are to be canceled and are not to be reimbursed, based on inactivity
              during this period.
            </p>

            <ol class="rb-page--list rb-page--list__main list-unstyled">
              <li class="increment">
                <h2>Return of credits to Your profile</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <p class="rb-page--list__a increment">
                      Return of credits to Your credit account is possible in cases mentioned in the
                      Terms of Use.
                    </p>
                    <p class="rb-page--list__a increment">
                      Refund of credits becomes also possible in case when You lost them due to
                      certain mistakes on the service. You have to confirm the fact of such system
                      failure by providing the evidence and after internal investigation made by the
                      administration of our service.
                    </p>
                    <div class="rb-page--list__a increment">
                      <p>
                        If the gift which was chosen by You cannot be delivered to the user for any
                        reason, in this case credit refund is possible.
                      </p>
                      <p>
                        In case when the gift was not sent or was rejected by the user, whom You
                        have selected before, or in case when You personally have changed Your mind
                        before sending, it becomes possible to get repayment of the credits to Your
                        account in full amount. But in case when the gift is sent but was not
                        delivered, then only partial credit compensation is possible. No later than
                        in 10 days from the date of sending the gift You will be informed about such
                        cases.
                      </p>
                      <p>
                        By using the feedback form it is possible to refuse to send the gift You
                        have ordered, but only in case if the gift has not yet been sent.
                      </p>
                      <p>
                        Please pay your attention to the fact that if the gift, that You have
                        selected was properly delivered to the user, You have no rights for
                        repayment of this gift’s cost.
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>
                        If You observe all the rules of the service, but You were denied in
                        providing access to the contact information of the user You have selected,
                        in this case credit reimbursement is possible.
                      </p>
                      <p>
                        No later than in 10 days from the date of sending the request for contact
                        information You will be informed of such cases.
                      </p>
                    </div>
                    <p class="rb-page--list__a increment">
                      The refund of credits is possible when You suspect that one of the
                      participants have cheated on You, in this case You are free to complain to
                      this user and provide reasoned evidence of the fact of fraud. The service can
                      partially or completely repayment the credits, that You have spent on
                      communication with this participant, if Your complaint is confirmed in the
                      result of the investigation. Such investigation is performed by the
                      administration of service {{ siteName }}.
                    </p>
                    <p class="rb-page--list__a increment">
                      Your Credits can be refunded due to any other factors that You described about
                      and sent through the application form. After the positive result of the
                      investigation, made by Administration of service {{ siteName }}, in case if the
                      veracity of Your claim is confirmed, the repayment is possible.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Bonus credits accrual</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <div class="rb-page--list__a increment">
                      <p>
                        {{ siteName }} can implement promotional and other activities without disclosing
                        this fact to users, with or without their notice. It follows that some bonus
                        credits will be credited to accounts of all participants or certain part of
                        users without charging additional fees.
                      </p>
                      <p>
                        Also, {{ siteName }} has the right unilaterally, with or without notice, without
                        any consent from users, to establish a discount on the cost of credit
                        packages, as well as the right to collect additional credits without
                        charging fee for them, for the performance of certain actions by the
                        participants (for example, for ordering and purchasing an auction credit
                        package).
                      </p>
                    </div>
                  </li>
                </ol>
              </li>
              <li class="increment">
                <h2>Money refund</h2>
                <ol class="rb-page--list rb-page--list__sub list-unstyled">
                  <li class="rb-page--list__li">
                    <div class="rb-page--list__a increment">
                      <p>
                        If You take decision to delete Your {{ siteName }} account and during the whole
                        period of using website You have not have any violations of provisions and
                        rules, You can be refunded by unused credits in money equivalent excluding
                        commission and costs of the processing service MultiCards OnlinePayments, as
                        long as 365 days have not passed since the moment of credits purchase. The
                        administration of the site is obliged to respond all our customers’ queries
                        via email or telephone within a maximum 48 hours).
                      </p>
                      <p>
                        The administration of {{ siteName }} is only one who decide on refund and establish
                        the final amount of such compensation in private, unilateral order and such
                        decision is not subject to appeal.
                      </p>
                      <p>
                        In this case, the amount of money are transferred to the card from which
                        Your last payment was made. Please, note, that all charges will be debited
                        from Your debit/credit card in real time.
                      </p>
                    </div>
                    <div class="rb-page--list__a increment">
                      <p>
                        You should directly contact the processing service MultiCards Online
                        Payments in case If you want to return the money you have lost in the result
                        of fails of the processing service itself and/or Your own mistakes made on
                        this service, or if You suspect that there may be scam with Your bank card.
                      </p>
                      <p>
                        Web service {{ siteName }} never requires or preserves any of Your bank details, as
                        well as never participate in the withdrawal of money amounts from Your bank
                        card. The processing service MultiCards Online Payments bears full
                        responsibility for these operations. In connection with this, the company
                        {{ settings.company_name }} is not responsible for any operations performed
                        on the processing service.
                      </p>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    document.title = 'Refund Policy';
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

p,
.rb-page .ul-styled li {
  margin-bottom: 0.5em;
}
</style>
