<template>
  <div>
    <p>
      Настоящие условия, описанные в этом соглашении, а также любые споры или претензии, которые могут возникать из или
      в связи с ними или их предметом (в том числе внедоговорные споры и претензии), регулируются и объясняются в
      соответствии с законодательством страны компании сервиса {{ siteName }}, см. 2.1. . При наличии противоречий между
      переводом текста соглашения и оригиналом на английском языке преимущественную силу будет иметь текст на английском
      языке.
    </p>
    <ol class="rb-page--list rb-page--list__main list-unstyled">
      <li class="increment">
        <h2>Общие положения</h2>
        <div class="rb-page--box">
          <p class="important">
            <strong>ЕСЛИ ВЫ НЕ СОГЛАСНЫ С ПОЛОЖЕНИЯМИ И УСЛОВИЯМИ НАСТОЯЩЕГО СОГЛАШЕНИЯ, ВОЗДЕРЖИТЕСЬ ОТ ИСПОЛЬЗОВАНИЯ
              НАШЕГО ВЕБ-ПРИЛОЖЕНИЯ И ПОДАЙТЕ ЗАЯВКУ НА УДАЛЕНИЕ СВОЕЙ УЧЕТНОЙ ЗАПИСИ.</strong>
          </p>
        </div>
        <ol class="rb-page--list__sub list-unstyled">
          <li class="rb-page--list__a increment">
            {{ siteName }} представлено в форме веб-приложения и используется как социальная сеть для знакомств. Целью сайта
            {{ siteName }} является создание пространства, в котором люди могли бы знакомиться, общаться, встречаться, делиться
            своим личным контентом и информацией. Веб-приложение {{ siteName }} расположено в сети интернет по веб-адресу
            <template v-if="full">
              <router-link :to="{name: 'index'}">
                www.redbini.com
              </router-link>
            </template>
            <template v-else>
              www.redbini.com
            </template>
            (далее – “веб-сайт”, “сервис”, “веб-приложение”). Использование {{ siteName }} предусматривает, что процесс общения
            будет происходить весело и непринужденно, поэтому для нас и для Вас важно, чтобы наша сеть оставалась
            дружелюбным и безопасным местом. Таким образом, Вы соглашаетесь использовать {{ siteName }} и обязуетесь не
            нарушать условия и положения настоящего соглашения.
          </li>
          <li class="rb-page--list__a increment">
            Просим Вас ознакомиться с положениями нашей Политики конфиденциальности, в которой описано, как мы
            обрабатываем Ваши личные данные и защищаем Вашу конфиденциальность, в случае если Вас беспокоит, каким
            образом {{ siteName }} использует Вашу личную информацию во время нахождения на сайте {{ siteName }}. Пользуясь {{ siteName }},
            Вы соглашаетесь с тем, что мы будем использовать и обрабатывать Ваши персональные данные в соответствии с
            нашей Политикой конфиденциальности.
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Право стать пользователем {{ siteName }}</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Стороны, между которыми заключается настоящее соглашение
            </p>
            <p class="rb-page--list__a increment">
              Настоящие Условия представляют собой юридически обязательное соглашение между Вами (далее «Пользователь»
              или «Участница») и {{ settings.company_name }} - компании с юридическим адресом:
              {{ settings.company_address }} (далее - «мы» или «Компания» или «{{ siteName }}»).
            </p>
            <p class="rb-page--list__q">
              Чтобы стать пользователем нашего сервиса необходимо:
            </p>
          </li>
          <li>
            <p class="rb-page--list__a increment">
              Найти партнера данного сервиса (CPA партнера), соответствовать требованиям сервиса и иметь желание для
              регистрации профайла.
            </p>
            <p class="rb-page--list__a increment">
              Вы можете зарегистрироваться на веб-сайте и использовать его сервисы и другие приложения от {{ siteName }}
              <strong>только
                в том случае, если Вам уже исполнилось 18 лет (или если в стране Вашего проживания установлен другой
                возраст достижения совершеннолетия)</strong> . Вы должны иметь все соответствующие документы,
              необходимые для
              подтверждения этого факта на момент регистрации.
            </p>
            <p class="rb-page--list__a increment">
              Принимая настоящее соглашение, Вы подтверждаете, что имеете право и способность связать на себя
              обязательство соблюдения условий и положений настоящего соглашения. Пользуясь {{ siteName }}, Вы обязуетесь не
              нарушать положения международного законодательства и законодательных норм своей страны, а также настоящего
              соглашения. Вы единолично несете ответственность за соблюдение всех существующих местных законов и норм, а
              также настоящего соглашения.
            </p>
            <p class="rb-page--list__a increment">
              Соглашаясь с условиями настоящего соглашения, Вы подтверждаете, что никогда не были признаны судом
              виновными в мошенничестве, финансовых махинациях, общественно опасном посягательстве, причинении
              физического вреда или сексуальном домогательстве, в преступлениях, носящих сексуальный характер, и что Вы
              не были осуждены за любое преступление нравственной распущенности, и в отношении Вас не ведется уголовное
              дело в отношении указанных выше преступлений.
            </p>
            <p class="rb-page--list__a increment">
              В случае обнаружения факта, что Вы не можете быть клиенткой сервиса
              {{ siteName }}, Ваша учетная запись будет удалена, а к Вашему представителю (наш CPA партнер) будут применены
              штрафные санкции.
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Сроки действия соглашения</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Вступление в силу настоящего соглашения и прекращение его действия
            </p>
            <p class="rb-page--list__a increment">
              Настоящее соглашение вступает в законную силу с момента Вашего первого входа на {{ siteName }}, и не зависит от
              наличия регистрации, поэтому мы просим Вас прочитать его внимательно. Выполняя любые действия на веб-сайте
              и сервисах от {{ siteName }}, (в том числе выполняя вход, регистрируясь, используя или получая услуги) Вы
              принимаете условия и положения настоящего соглашения и обязуетесь не нарушать их.
            </p>
            <p class="rb-page--list__a increment">
              Настоящее соглашение действует на протяжении всего времени использования веб-сайта {{ siteName }} и его сервисов,
              вплоть до окончания выполнения всех Ваших действий на данном ресурсе, или прекращения действия Вашей
              учетной записи (приостановки действия или удаления).
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Использование веб-приложения и правила обращения с контентом</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Возможность иметь учетные записи в других социальных сетях
            </p>
            <p class="rb-page--list__a increment">
              Вы имеете полное право пользоваться другими социальными сетями если будете соблюдать следующее правило:
              при условии регистрации своей учетной записи на {{ siteName }}, Вы должны следить за своими фотографиями,
              статусами и остальными материалами в других социальных сетях, которые могли остаться после Ваших
              предыдущих отношений или прошлого брака. В противном случае, мы воспримем подобные действия как обман с
              Вашей стороны в отношении нашего сервиса, что повлечет за собой санкции, описанные в п 4.7.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Размещение материалов на {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                На веб-сайте {{ siteName }}, Вы имеете право размещать разные материалы включая инфографику, фотографии,
                видеоролики, тексты и другие материалы (далее «Контент»), на которые не распространяются сторонние
                авторские права. Материалы, которые Вы размещаете материалы не должны противоречить условиям настоящего
                соглашения, нормам международного и местного законодательства.
              </p>
              <p>
                Правила определения того, какие материалы следует считать приемлемыми. Вы не должны размещать или
                отправлять Контент, который:
              </p>
              <ul class="ul-styled">
                <li>
                  содержит ругательства или оскорбления, которые могут обидеть собеседников Ваших клиенток и
                  администрацию сервиса;
                </li>
                <li>является непристойным, порнографическим или иным образом оскорбляющим другого человека;</li>
                <li>
                  является оскорбительным, унижающим или угрожающим, либо подстрекает к расизму, сексизму, ненависти и
                  нетерпимости;
                </li>
                <li>
                  призывает к ведению незаконной деятельности, включая терроризм, расовую вражду, вымогательство
                  материальных благ и финансовых средств, либо публикацию подобных материалов, что является уголовным
                  преступлением;
                </li>
                <li>является клеветническим;</li>
                <li>
                  относится к коммерческой деятельности (включая, без ограничений, продажи, рекламу, ссылки на другие
                  сайты или платные телефонные номера) или является спамом;
                </li>
                <li>относится к коммерческой или другого вида тайне;</li>
                <li>
                  содержит исходные коды или ссылки на шпионские программы, рекламные программы, вирусы, поврежденные
                  файлы, программы-черви либо другой вредоносный код, имеющий целью нарушить, повредить, ограничить или
                  прервать работоспособность любого программного обеспечения, оборудования, телекоммуникаций, сетей,
                  серверов; «троянских коней», созданных с целью повредить, вмешаться в работу, незаконно перехватить
                  или изъять любую информацию (в том числе личную), будь то посредством {{ siteName }} или без;
                </li>
                <li>
                  материал, который сам по себе, либо факт размещения которого нарушает права третьей стороны
                  (включая, без ограничений, интеллектуальные права и конфиденциальность);
                </li>
                <li>
                  представляет собой изображение другого человека, если Контент был создан/распространён без согласия
                  этого лица.
                </li>
              </ul>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Правила относительно содержания Вашей персональной информации
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Вам запрещено публиковать или распространять (любым способом) чужую личную, контактную или банковскую
                информацию на своей странице, размещенной на нашем сервисе (далее “Профайл”), или в любом другом месте
                на нашем сервисе (например: имена, домашние и почтовые адреса, номера телефонов, электронные адреса,
                URL, номера банковских карт).
              </p>
              <p>
                Ваш Профайл должен содержать только правдивую информацию в соответствии со всеми разделами и графами,
                присутствующими в форме для заполнения, в заявке на регистрацию или размещение Контента на нашем
                сервисе.
              </p>
              <p>
                На веб сайте <strong>{{ siteName }}</strong>
                Вам запрещено самолично, в обход правилам, существующим на сервисе, раскрывать свою личную контактную
                информацию собеседнику противоположного пола
              </p>
              <p>
                Указывать и/или раскрывать другим пользователям персональную информацию (адрес персональной электронной
                почты, контактный номер телефона и/или другие сведения) и/или любую другую информацию о себе возможно
                только на основании запроса и актуального видео-согласия. Видео-согласие должно быть с Вашим
                присутствием в кадре (лицо должно быть видно отчетливо) с документом, удостоверяющим личность (на
                страницах которого отчетливо должны быть видны фамилия, имя и отчество, Ваш возраст, серийный номер
                документа), на основании международного законодательства и местных законов об использовании личной
                информации. Видео-подтверждение должно быть загружено на сервис Вашим представителем (наш CPA партнер) и
                должно пройти верификацию у нашего модератора.
              </p>
              <p>
                Мы имеем право раскрыть Вашу личную информацию третьему лицу в случае, если от него нам поступит жалоба
                о том, что Контент, загруженный Вами в наше веб-приложение, нарушает авторское право третьих лиц, их
                право на конфиденциальность или любой другой законодательный акт.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Использование персональной информации других пользователей
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Вам запрещено использовать персональную, контактную, банковскую или любую другую информацию других
                пользователей {{ siteName }}, даже в том случае, когда подобная информация была передана Вам добровольно. Вы не
                имеете права использовать любую информацию о других пользователях, если она выходит за рамки информации,
                достаточной для знакомства и поддержания разговора на нашем сервисе.
              </p>
              <p>
                Использование подобной информации в коммерческих целях, для рассылки спама, угроз, вымогательства,
                домогательств, шантажа или иных противозаконных целях исключается.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Предоставление информации, такой как логин и пароль, другим людям для входа в учетную запись под Вашим
              именем
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Право доступа к Вашей учетной записи на {{ siteName }} имеете только Вы. Вы не должны никому передавать свой
                логин и пароль, так как это ставит под угрозу сохранность личной информации и Контента, загруженного
                Вами на “{{ siteName }}”. Вы несете ответственность за сохранность своего логина и пароля.
              </p>
              <p>
                В случае такого нарушения {{ siteName }} не несет ответственности за несанкционированный доступ к Вашему
                Профайлу. Неправомерный доступ к учетной записи приводит к ее полной и безвозвратной деактивации.
              </p>
              <p>
                Если вы подозреваете, что кто-то получил доступ к Вашей учетной записи, Вы должны немедленно сообщить
                нам или Вашему представителю (наш CPA партнер) об этом через форму обратной связи или иным способом.
                Также Вам следует немедленно изменить свой логин и пароль.
              </p>
              <p>
                {{ siteName }} оставляет за собой право удалить Вашу учетную запись в случае нарушения Вами правил сохранности
                логина и пароля.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Возможность видеть Ваш Контент, опубликованный на {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                При загрузке Вашего Контента в свой профайл или на сайт {{ siteName }}, появляется возможность всем
                пользователям мужского пола видеть его. Если Вы не хотите, чтобы другие пользователи видели Ваши личные
                материалы, не размещайте их на {{ siteName }}. Когда кто-либо противоправным способам создал учетную запись на
                нашем сервисе используя Вашу личную информацию и/или личный контент, Вы имеете полное право сообщить об
                этом нашему сервису через обратную связь вместе с датированным и актуальным видеообращением,
                прикрепленным к Вашему сообщению (с присутствующем в кадре документом, удостоверяющем Вашу личность), и
                мы деактивируем эту учетную запись в течении 14 календарных дней.
              </p>
              <p>
                Мы оставляем за собой полное право без или с уведомлением удалять, редактировать, ограничивать или
                блокировать доступ к Вашей учетной записи, а также выполнять эти действия в отношении Контента,
                размещенного в Вашем Профайле, в том случае, если у нас будут разумные основания полагать, что Ваш
                Профайл содержит Контент, нарушающий положения настоящего соглашения или международное
                законодательство.
              </p>
              <p>
                Мы оставляем за собой полное право без или с уведомлением публиковать Ваш Контент с целью рекламы нашего
                сервиса и сторонних сервисах (за исключением личных фотографий, адресов электронной почты, номера
                контактного телефона и паспортных данных), в публичной части нашего сервиса и в рассылочных письмах
                среди пользователей мужского пола, а также на лендингах или рекламных баннерах, или слайдерах.
              </p>
              <p>
                Мы имеем полное право проверять Ваш Контент и информацию (включая личные данные), размещаемую на веб
                сайте {{ siteName }}, на подлинность и действительность, методами верификации адреса электронной почты,
                контактного номера телефона и загруженного видео-подтверждения (с учетом требований, описанных в п. 4.3
                и 4.2), на предмет соответствия настоящему соглашению и международному или местному
                законодательству.
              </p>
              <p>
                Кроме того, мы имеем полное право отслеживать Ваш персональный Контент и информацию (включая личные
                данные), за весь период существования Вашего Профайла на нашем сервисе, а также отслеживать то, как и с
                какой целью Вы используете {{ siteName }}.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Ответственность за нарушения правил размещения материалов
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Наш сайт не несет ответственности за материалы, личные данные или любую другую информацию, которую Вы
                размещаете на {{ siteName }} через сайт или его сервисы лично или в случае, если эти действия были выполнены
                другим лицом от Вашей учетной записи.
              </p>
              <p>
                Пожалуйста, пользуйтесь здравым смыслом при размещении выбранного вами Контента на {{ siteName }}, так как вы
                единолично несете за него полную ответственность. В противном случае, Ваша учетная запись будет
                безвозвратно заблокирована, на Вашего представителя (наш CPA партнер) будут наложены штрафные санкции, и
                материалы о незаконной деятельности будут для дальнейшего разбирательства переданы в Интерпол и
                правоохранительные органы Вашей страны.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Право собственности на Контент</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Право на Контент после его загрузки на {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Если Вы являетесь правообладателем на Контент и можете это подтвердить, то Вы имеете все права размещать
                его на сайте.
              </p>
              <p>
                Просьба принять к сведению, что, загружая Контент на {{ siteName }}, Вы тем самым подтверждаете, что имеете на
                это право. Вы автоматически передаете нам безвозмездную, бессрочную, глобальную лицензию на
                использование данного Контента любым способом (включая, без ограничения, копирование, редактирование,
                изменение, перевод, реформатирование, использование в других материалах, изготовление производных
                материалов, рекламу, распространение и публикацию Контента для широкого круга пользователей
                противоположного пола, частично или полностью, в любом формате и на любом носителе, который уже
                существует или будет разработан в будущем, за исключением данных Вашего паспорта гражданина страны,
                приватных фотографий и адреса электронной почты, а также личного контактного номера телефона).
              </p>
              <p>
                Мы можем передать вышеуказанную лицензию аффилированным компаниям и правопреемникам без каких-либо
                согласований с Вами.
              </p>
              <p>
                Загружая Контент на {{ siteName }}, Вы подтверждаете, что Вы являетесь эксклюзивным автором и владельцем
                данного Контента и согласны отказаться от моральных прав автора на этот Контент (включая, без
                ограничения, право далее считаться автором).
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Принадлежность всего остального Контента на веб-сайте {{ siteName }}
            </p>
            <p class="rb-page--list__a increment">
              Весь материал, такой как текст, графика, пользовательский интерфейс, торговые марки, звуки, логотипы и
              художественное творчество {{ siteName }} принадлежит сайту, контролируется или лицензировано нами, поэтому весь
              контент защищен правом собственности, торговой маркой и другими правами интеллектуальной
              собственности.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Использование Контента, который Вам не принадлежит
            </p>
            <p class="rb-page--list__a increment">
              В случае, если у Вас нет прав на Контент, Вы не можете использовать его на {{ siteName }}. Вы соглашаетесь, что
              своими действиями Вы не нарушаете прав третьих сторон. Это означает, что Вы не будете копировать,
              изменять, распространять, публиковать или продавать Контент {{ siteName }} (кроме своего собственного). В случае
              нарушения положений раздела 5 настоящего соглашения Вы несете ответственность согласно пункту 4.7.
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Информация, которую мы собираем</h2>
        <p>
          Очевидно, что мы не сможем помочь Вам заводить новые знакомства, если мы не будем располагать некоторой
          информацией о Вас, такой как основные данные профиля и характеристики людей, с которыми Вы хотите
          познакомиться. Мы в праве собирать информацию, которая генерируется при использовании Вами наших сервисов,
          например, журналы регистрации доступа. Если Вы хотите получить дополнительную информацию, то мы более подробно
          расскажем об этом ниже.
        </p>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Информация, которую вы нам предоставляете
            </p>
            <p>
              При использовании наших сервисов, Вы предоставляете нам определенную информацию. К ней относится
              следующее:
            </p>
            <p class="rb-page--list__a increment">
              Когда Вы создаете учетную запись, Вы предоставляете нам свои имя пользователя и пароль, а также
              основную информацию, необходимую для работы сервиса: ваш пол, дату рождения, описание вашего характера,
              информацию о стиле жизни, о интересах и прочую информацию, а также фото и видео. Для того, чтобы
              верифицировать свой аккаунт и подтвердить совершеннолетие, а также тот факт что в данный момент Вы не
              состоите в браке, Вы предоставляете нам скан страниц гражданского паспорта, свой номер телефона, адрес
              электронной почты, и видео-подтверждение о желании зарегистрироваться на сервисе {{ siteName }}. Для добавления
              определенного Контента, например, изображений или видео, Вы можете предоставить нам доступ к Вашей камере
              или фотоальбому. Некоторая информация, которую Вы можете предоставить нам, считается особой или
              конфиденциальной в некоторых странах, например Ваше расовое или этническое происхождение, сексуальная
              ориентация и религиозные верования. Предоставляя нам эту информацию, Вы соглашаетесь на ее обработку с
              нашей стороны.
            </p>
            <p class="rb-page--list__a increment">
              Во время участия в наших конкурсах либо мероприятиях, наш сайт
              собирает информацию, предоставленную Вами при регистрации или при входе в систему.
            </p>
            <p class="rb-page--list__a increment">
              Когда Вы связываетесь с нашим центром клиентской поддержки, мы
              собираем информацию, которую Вы нам предоставляете во время чатов и разговора. Иногда мы записываем или
              отслеживаем разговоры в учебных целях и для того, чтобы обеспечить высокое качество оказываемых услуг.
            </p>
            <p class="rb-page--list__a increment">
              Если у Вас будет желание связаться с кем-либо вне сервиса, а
              также передать ваш e-mail тому, кто запросил его у Вас, то мы, собирая информацию о Вас, выполняем вашу
              просьбу.
            </p>
            <p class="rb-page--list__a increment">
              Для обеспечения функционирования сервисов, мы также обрабатываем
              Ваше общение в чате с другими пользователями и Контент, который Вы публикуете.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Информация, которую мы получаем от других лиц
            </p>
            <p>
              В дополнение к информации, которую Вы нам предоставляете напрямую, мы также получаем информацию о Вас от
              других лиц, включая:
            </p>
            <ul class="ul-styled">
              <li>
                <strong>Других пользователей</strong>. Другие пользователи могут предоставлять информацию о Вас при
                использовании
                ими наших сервисов. Например, мы можем собирать информацию о Вас от других пользователей, если они
                свяжутся с нами, чтобы предоставить ее.
              </li>
              <li>
                <strong>Других партнеров</strong>. Мы можем получать информацию о Вас от наших партнеров, например,
                ту, которая
                публикуется на партнерских сайтах и платформах (в данном случае они могут передавать информацию об
                успешности рекламной кампании).
              </li>
            </ul>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Существует ли чат на {{ siteName }}?
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Чат. На {{ siteName }} существуют текстовый, видео и приватный чат. Видеочат невозможно начать, если у Вас нет
                web-камеры или она отключена, а также, если собеседники отклонил заявку на видео-чат и перешел в
                текстовый. Вы не можете самостоятельно перейти или отклонить заявку в приватный чат, в случае если у Вас
                есть активный видеочат, Ваш собеседник может изменить его статус на приватный.
              </p>
              <p class="rb-page--list__q">
                Информация, собираемая при использовании наших сервисов
              </p>
              <ul class="ul-styled">
                <li>
                  <strong>Информация об использовании</strong>. Мы собираем информацию об использовании Вами наших
                  сервисов,
                  например, каким образом Вы их используете (дата и время входа в систему, функции, которые Вы
                  используете, поиск, клики и страницы, которые отображаются для Вас, адреса страниц, с которых Вы были
                  перенаправлены, реклама, на которую Вы нажимаете), и как Вы общаетесь с другими пользователями
                  (например, пользователи, с которыми Вы связываетесь и взаимодействуете, время и дата вашего контакта,
                  количество сообщений, которые Вы получаете и отправляете).
                </li>
                <li>
                  <strong>Информация об устройстве</strong>. Мы собираем информацию с Ваших устройств, с помощью
                  которых Вы
                  используете наши сервисы, включая: информацию об аппаратном и программном обеспечении, например,
                  IP-адрес, идентификатор устройства и его тип, тип браузера, версия и язык, операционная система,
                  часовые пояса, идентификаторы, связанные с cookies или другие технологии, которые могут
                  идентифицировать аше персональное устройство или браузер (например, IMEI/UDID и адрес MAC);
                </li>
                <li>
                  Информацию о Вашем беспроводном или мобильном интернет-соединении, например о провайдере и
                  мощности сигнала;
                </li>
                <li>Информацию о датчиках вашего устройства, таких как акселерометры, гироскопы и компасы.</li>
                <li>
                  <strong>Другая информация, предоставляемая с Вашего согласия</strong>. С Вашего разрешения мы можем
                  собирать
                  информацию о Вашем точном местонахождении (широта и долгота) с помощью различных средств, взависимости
                  от того какие сервисы и устройство Вы используете, включая GPS, соединения Bluetooth или Wi-Fi. Сбор
                  данных о Вашем местонахождении может происходить в фоновом режиме, даже, когда Вы не пользуетесь
                  нашими услугами, если Вы дали нам разрешение на сбор этой информации. Если Вы не дадите нам разрешения
                  на получение информации о Вашем местонахождении, то мы не будем ее собирать. Подобным образом, при
                  наличии Вашего согласия, мы можем собирать Ваши фото и видео (например, если Вы захотите опубликовать
                  фотографию, видео, или прямую трансляцию на одном из наших сервисов).
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Основные сервисы Вашего аккаунта в веб-приложении {{ siteName }}</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Получение подарков на сайте {{ siteName }}
            </p>
            <p class="rb-page--list__a increment">
              {{ siteName }} даёт Вам возможность получать подарки от пользователей противоположного пола через сайт.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Передача подарка другому пользователю {{ siteName }}
            </p>
            <p class="rb-page--list__a increment">
              Вы не имеете права передавать другим пользователям подарки, полученные через {{ siteName }} от пользователей
              противоположного пола.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Срок подтверждения получения подарка, отправленного через {{ siteName }} пользователем противоположного пола
            </p>
            <div class="rb-page--list__a increment">
              <p>
                У Вас есть 5 дней на подтверждение или отмену пришедшей заявки на получение подарка от курьера в Вашем
                регионе/стране, отправленной Вашим собеседником через {{ siteName }}.
              </p>
              <p>
                После подтверждения Ваш подарок будет доставлен в течение 5 календарных дней и передан Вашим
                представителем в Вашем регионе, который на основании договора CPA партнерства представляет наш сервис в
                Вашей стране/регионе и курирует Вашу учетную запись на {{ siteName }}.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Адресаты корреспонденции
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Вы имеете возможность написать письмо любому зарегистрированному пользователю сервиса мужского пола
                (независимо от того, имеет ли он статус онлайн). При этом длина письма должна быть не более 5000
                символов, но не менее 200 и только на английском языке. К письму Вы можете прикрепить не более 3 единиц
                контента (фото и видео с вашей публичной и приватной галереи).
              </p>
              <p>
                В письмах запрещается отправлять какие-либо ссылки, адреса сторонних сайтов, адреса электронной почты,
                контактные номера телефонов. В переписке также запрещено использовать любые материалы, в том числе и
                текст, противоречащий разделу 4 настоящего соглашения.
              </p>
              <p>
                На входящие письма Вы обязаны ответить в течении 48 часов с момента получения входящего письма от
                собеседника.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Доступность чата на {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                На {{ siteName }} существуют следующие виды чатов: приватный, текстовый и видео-чат. Последний возможен лишь
                при наличии web-камеры, которая должна быть включена. Видео-чат невозможен, если собеседник отклонил
                заявку на видео-чат и перешел в текстовый. Вы не можете самостоятельно перейти или отклонить заявку в
                приватный чат, в случае если у Вас есть активный видео-чат, Ваш собеседник может изменить его статус на
                приватный.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Возможность приглашать пользователей в чат
            </p>
            <div>
              <p>
                Вы имеете право приглашать всех зарегистрированных пользователей противоположного пола, имеющих статус
                онлайн.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Начало разговора/чата с пользователем
            </p>
            <div>
              <p>
                Вам нужно найти подходящего собеседника среди всех зарегистрированных пользователей со статусом онлайн
                и отправить ему через комнату чата первое сообщение (далее “Инвайт). Ваш собеседник начинает с Вами
                общение или отклоняет Ваш инвайт. Ваш собеседник также может отправить Вам инвайт, который действителен
                в течении 20 секунд.
              </p>
              <p>
                Чат разрывается после 60 секунд бездействия в активном текстовом или видео-чате. Бездействием считается
                неактивность обоих или хотя бы одного из собеседников, время которой составляет более 60 секунд после
                ответа на последнее сообщение. Приватный чат разрывается только после того, как Ваш собеседник решил его
                завершить.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Необходимые средства для качественного чата
            </p>
            <div>
              <p>
                Ваше мобильное устройство и/или компьютер должны соответствовать рекомендованным системным требованиям
                нашего веб-сайта и сервисов {{ siteName }}. Также Вам необходимо Интернет соединение высокого качества через
                выделенную сеть интернет со скоростью соединения не менее 50 Мбит/с и ping не более 100 ms, или не менее
                качественное соединение через мобильного оператора. Так же мы советуем Вам использовать более новые
                web-камеры с разрешением менее 720p.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Возможность делиться Вашей личной контактной информацией с собеседником противоположного пола
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Контактная информация. Для этого Ваш представитель (наш CPA партнер) должен получить заявку от Вашего
                собеседника на получение Ваших контактов через партнерскую административную панель. После чего Ваш
                представитель известит Вас об этом. Для того, чтобы Ваш собеседник мог направить подобный запрос, Вы
                должны быть в фаворитах друг у друга, иметь определенную продолжительность общения через корреспонденцию
                и чат.
              </p>
              <p>
                После того как Вы примете подобный запрос, Вам необходимо повторно выполнить действия, описанные в п
                4.3 настоящего соглашения, в отношении Вашего представителя. После всех соблюденных процедур Вами и
                Вашим представителем наш сервис раскроет контактные данные Вашему собеседнику.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <div class="rb-page--list__a increment">
              <p>
                В случае нарушения положений, раздела 7 настоящего соглашения Вы единолично несете ответственность за
                свои действия. В данном случае Ваша учетная запись может быть безвозвратно или временно заблокирована,
                на Вашего представителя могут быть наложены штрафные санкции и материалы о незаконной деятельности для
                дальнейшего разбирательства могут быть переданы в Интерпол и правоохранительные органы Вашей страны.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Доступ к веб-приложению {{ siteName }}</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Гарантия целостности и долгосрочности сервиса {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Так как с веб-приложением могут случаться непредвиденные ситуации и время от времени нам приходится
                проводить ремонтные работы, то, к сожалению, мы не можем дать такой гарантии, Из этого следует, услуги
                {{ siteName }} предоставляются по принципу “как есть”. Мы не можем дать никаких гарантий относительно точности,
                качества, функциональности, доступности и работоспособности {{ siteName }} и оставляем за собой право
                приостанавливать, отключать, дополнять или изменять функционал, дизайн или услуги {{ siteName }}, без
                предупреждения и возникновения каких-либо обязательств по отношению к Вам.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Вход на сервис через мобильные устройства
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Да, это возможно. Ваша задача состоит в том, чтобы сделать всё, что от Вас зависит, для обеспечения
                своего доступа к {{ siteName }} (включая, но не ограничиваясь, платежами вашему интернет-провайдеру и оператору
                мобильной связи, равно как и любыми другими платежами, связанными с возможностью доступа).
              </p>
              <p>
                Мы не несём ответственности в случаях, когда функциональность и/или внешний вид сервисов {{ siteName }}
                нарушены, или ограничены, или отображаются в неполной мере в результате ошибок подключения к сети
                интернет, аварий на линиях передач, или авариях на вышках сотовой связи, или в результате иных других
                неполадок Вашего оборудования или оборудования Ваших провайдеров, независимо от устройства или
                приложений, которые Вы используете или будете использовать (существующие в настоящее время или которые
                будут созданы в будущем) для входа в наши сервисы.
              </p>
              <p>
                Подключаясь к {{ siteName }} или соглашаясь получать сообщения и уведомления от {{ siteName }} на свой мобильный
                телефон и/или любое другое устройство, Вы осознаете, что Ваш интернет провайдер или провайдер мобильной
                связи может взимать с Вас дополнительную плату. Мы не несем за это ответственности.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Доступность определенного контента {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                У незарегистрированных или неавторизированных пользователей нет профайла и нет возможности загружать
                Контент, поэтому такие пользователи могут видеть только общедоступную часть Контента.
              </p>
              <p>
                Мы можем иногда изменять или дополнять общедоступные материалы на свое усмотрение и без
                предварительного уведомления.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Прекращение предоставления доступа</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Блокировка Вашего аккаунта
            </p>
            <div class="rb-page--list__a increment">
              <p>
                При наличии разумных оснований считать, что Вы нарушили положения настоящего соглашения и/или нормы
                международного и/или местного законодательства, то мы имеем полное право на своё усмотрение, без
                возникновения каких-либо обязательств относительно Вас (финансовых или иных):
              </p>
              <ul class="ul-styled">
                <li>
                  безвозвратно или временно заблокировать Вашу учетную запись, (если она существует), а также мы
                  можем лишить Вас права в дальнейшем использовать веб-сайт {{ siteName }} и/или его сервисы;
                </li>
                <li>
                  использовать любые операционные, технологические, юридические и другие средства, чтобы обеспечить
                  соблюдение Условий (включая, без ограничения, блокировку определённых IP-адресов).
                </li>
              </ul>
              <p>
                Мы постараемся (но мы не обязаны) известить Вас, если Ваш доступ к {{ siteName }} и/или к Вашему профайлу
                подлежит приостановке или прекращению.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Удаление учетной записи</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Удаление профайла с сайта {{ siteName }}
            </p>
            <div>
              <p>
                Вы можете когда-угодно удалить свой профайл, в том случае, если Вы зарегистрированы на нашем веб-сайте.
                Для этого Вы должны предоставить электронную копию Вашего гражданского паспорта и видеообращение с
                просьбой об удалении профайла с нашего сервиса. В видеообращении Вы должны держать в руках свой
                гражданский паспорт, открытый на 2-й странице, с обоснованием причины желаемого действия. В течение 14
                календарных дней мы удалим Вашу учетную запись в корзину.
              </p>
              <p>
                Вышеуказанные данные следует выслать посредством формы обратной связи, через кабинет Вашей учетной
                записи или через гостевой вход {{ siteName }}. Если Вы забыли логин и пароль от своей учетной записи, укажите в
                обращении адрес электронной почты и номер id Вашей учетной записи на сервисе {{ siteName }}.
              </p>
              <p>
                Мы сохраняем ваш Профайл на случай, если Вы решите восстановить свою учетную запись. Многие
                пользователи деактивируют свои аккаунты по временным причинам и соответственно ожидают, что мы сохраним
                их информацию к моменту их возвращения на {{ siteName }}. Поэтому у Вас есть возможность посредством заявки
                восстановить учетную запись и профайл в полном объеме в течение неопределенного срока после их
                деактивации. После деактивации Вашей учетной записи мы оставляем за собой право удалить любой Контент,
                загруженный Вами на {{ siteName }}. Для получения дополнительной информации, пожалуйста, прочтите нашу Политику
                конфиденциальности.
              </p>
              <p>
                Если Вы выходите замуж за пользователя данного сервиса или за мужчину, который не зарегистрирован на
                {{ siteName }}, то Вы обязаны сообщить нашему сервису об этом, а также предоставить электронную копию
                документов, удостоверяющих этот факт. В случае, если Вы выходите замуж за пользователя данного сервиса,
                Вы обязаны предоставить фото и видео-контент со свадьбы. После этого мы в течение 14 календарных дней
                удалим Вашу учетную запись в корзину.
              </p>
              <p>
                Контент, который Вы загрузили на наш сервис, кроме вашего Профайла (например, комментарии или
                сообщения в переписке с Вашими собеседниками), может отображаться на веб-сайте после деактивации Вашего
                профайла.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Оскорбления и жалобы</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Жалобы о нарушении условий пользования сайтом
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Чтобы пожаловаться на Контент, размещенный на {{ siteName }}, а также сообщить о нарушениях, свяжитесь с нами
                через страницу обратной связи и кратко изложите суть нарушения.
              </p>
              <p>
                Мы всегда стараемся помочь в решении любой проблемы, которая может возникнуть у Вас с нашим
                сервисом.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Политика конфиденциальности</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p>
              ЕСЛИ ВЫ НЕ СОГЛАСНЫ С НАШЕЙ ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ, ВОЗДЕРЖИТЕСЬ ОТ ИСПОЛЬЗОВАНИЯ ВЕБ-ПРИЛОЖЕНИЯ
              {{ siteName }} И ПОДАЙТЕ ЗАЯВКУ НА УДАЛЕНИЕ СВОЕЙ УЧЕТНОЙ ЗАПИСИ.
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Использование чужих персональных данных является большой проблемой пользователей социальных сетей для
                знакомств, поэтому мы в {{ siteName }} разработали настоящую Политику конфиденциальности (“Политика”), чтобы Вы
                знали, как мы используем личную информацию (“персональные данные” или “личная информация”) всех
                пользователей сервиса.
              </p>
              <p>
                Эта Политика действует каждый раз, когда Вы пользуетесь
                <router-link
                  v-if="full"
                  :to="{name: 'index'}"
                >
                  www.redbini.com
                </router-link>
                <template>www.redbini.com</template>
                ({{ siteName }} или “браузерное приложение”) и/или его сервисами, и она действует вместе со всеми правилами
                настоящего соглашения. Используя {{ siteName }} как пользователь, Вы соглашаетесь с Политикой
                конфиденциальности и даете разрешение на сбор, хранение, обработку, использование и распространение
                личной информации согласно данной Политике.
              </p>
              <p>
                {{ siteName }} оставляет за собой право на изменение Политики конфиденциальности в любое время без
                предварительного уведомления. Просьба иногда просматривать этот раздел, чтобы оставаться в курсе того,
                как мы используем Вашу информацию.
              </p>
            </div>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Мы можем использовать любую Вашу информацию или материалы, которые Вы размещаете в открытом доступе на
              веб-сайте {{ siteName }}, в рекламных целях на веб-сайтах и/или приложениях наших партнёров. Мы считаем, что это
              позволит нам улучшить наш веб-сайт, и лучше приспособить Ваш онлайн-опыт для удовлетворения Ваших
              потребностей.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Мы используем личную и иную информацию о Вас как о пользователе для разрешения споров, устранения
              неточностей и обеспечения соблюдения положений настоящего партнерского соглашения.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Помните, что запрещается использовать личную или иную информацию о других пользователях наших сервисов для
              противозаконных целей, коммерческих целей, спама, шантажа, угроз и оскорблений.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Мы собираем дополнительную информацию о Вас как о пользователе путём размещения файлов cookies на
              компьютере или мобильном телефоне. Файл Сookies - это фрагмент текста, который Ваш веб-браузер сохраняет
              на компьютере или мобильном устройстве. Сookies хранит информацию о посещении веб-сайта или партнерской
              административной панели, узнает предпочтения пользователей при каждом посещении {{ siteName }} и обеспечивает
              функциональность веб-сайта, позволяя нам обеспечивать работу существующего функционала и разрабатывать
              улучшения для CPA партнеров и пользователей {{ siteName }}.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Когда Вы посещаете {{ siteName }}, мы используем cookies для сохранения сессии. Файл Cookies с сессией удаляется
              при закрытии браузера. Мы также сохраняем постоянные файлы cookie (также известные, как «локальные
              запоминающие устройства») на компьютерах пользователей, так, чтобы сервис {{ siteName }} помнил пользователей и
              делал процесс авторизации пользователя более быстрым и легким. Мы можем использовать постоянные файлы
              cookies на Вашем мобильном устройстве, в основном для целей обеспечения безопасности, например, для
              предотвращения фишинга (действий по перехвату логинов и паролей), защиты от мошенников, попыток
              несанкционированного входа в систему, и чтобы помочь Вам получить доступ в случае, если Ваша учетная
              запись была взломана. Мы не используем информацию после того, как Вы выходите из системы.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              В настройках браузера или мобильного устройства Вы можете заблокировать сохранение файлов cookies и работу
              локальных устройств хранения данных, но при такой блокировке Вы не сможете в полной мере использовать
              функции сервисов {{ siteName }}.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Мы принимаем разумные меры предосторожности против возможных нарушений безопасности нашего приложения,
              веб-сайта, базы данных пользователей, однако ни одно приложение, веб-сайт или передача данных через
              Интернет не являются полностью безопасными, и мы не можем гарантировать, что несанкционированный доступ,
              взлом, потеря данных или другие нарушения исключены полностью. Мы призываем Вас принимать необходимые меры
              для обеспечения конфиденциальности персональных данных, включая данные для входа, а также рекомендуем
              выходить из учетной записи после использования и очищать информацию, сохраненную браузером.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Мы не можем гарантировать безопасность Ваших личных данных в тот момент, когда происходит их передача на
              наш веб-сайт. Любая передача данных происходит на Ваш страх и риск. Как только мы получаем Вашу
              информацию, у нас есть функции безопасности, которые помогают предотвратить несанкционированный
              доступ.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Если Вы не заходили в свой профайл в течение 6 месяцев, мы можем деактивировать его в ходе нашего процесса
              очистки данных.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Ваша учетная запись пользователя веб-сайта {{ siteName }}, со всем содержимым, не подлежит передаче, и все права
              на него будут отменены в случае Вашей смерти.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Вы несете полную ответственность за нарушение положений раздела 12
              настоящего соглашения. В данном случае Ваш аккаунт может быть безвозвратно или временно заблокирован, на
              Вашего представителя могут быть наложены штрафные санкции, и материалы о незаконной деятельности могут
              быть для дальнейшего разбирательства переданы в Интерпол и правоохранительные органы Вашей страны.
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Ссылки</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Связанные ссылки, которые имеются в нашем веб-приложении {{ siteName }}
            </p>
            <div class="rb-page--list__a increment">
              <p>
                Веб-сайту {{ siteName }} и/или его сервисам разрешено содержать ссылки на другие ресурсы и/или сайты с
                возможностью совершения покупок, предоставляемых третьими лицами. Эти ссылки предоставляются Вам для
                Вашего ознакомления. Если Вы переходите по этим ссылкам, Вы можете быть перенаправлены на сайты третьих
                сторон. Сайты третьих сторон имеют свои собственные условия обслуживания и политику конфиденциальности,
                которые могут отличаться от наших. Отображение ссылок на сайты третьих лиц не означает одобрения нами
                контента третьих лиц, их сайтов или ресурсов.
              </p>
              <p>
                Обратите внимание, что мы не имеем никакого контроля и не несем ответственности за содержание сайтов
                третьих сторон или их ресурсов, в том числе (но не ограничиваясь) за соблюдение третьими лицами любых
                применимых законов, правил или положений настоящего соглашения.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Отказ от гарантий и ограничение ответственности</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Ответственность за несоблюдение правил поведения на сайте
            </p>
            <p class="rb-page--list__a increment">
              Вы и только Вы несете ответственность за последствия Ваших действий, которые могут обижать других
              пользователей. Мы отказываемся от ответственности за Ваше поведение и действия, или поведение и действия
              любого другого пользователя {{ siteName }}, а также отказываемся от ответственности за Контент, загружаемый Вами
              или любыми другими пользователями.
            </p>
          </li>
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Ответственность веб-сайта {{ siteName }} и/или его сервисов
            </p>
            <p class="rb-page--list__a increment">
              Ничто в настоящем соглашении, независимо от других положений, не может быть принято, чтобы привлечь наш
              сервис к ответственности за получение Вами телесных повреждений любой степени, включая те, что могли
              повлечь за собой смерть, в результате Ваших неосторожных действий и/или из-за невнимательности, при
              использовании и/или в результате использования веб-сайта {{ siteName }} и/или его сервисов. Мы исключаем любую
              свою ответственность, предусмотренную действующим международным и/или местными законами (нормативными
              актами), которая может наступить в результате Ваших неосторожных действий и/или из-за невнимательности,
              при использовании и/или в результате использования веб сайта {{ siteName }} и/или его сервисов.
            </p>
          </li>
          <li class="rb-page--list__li">
            <div class="rb-page--list__a increment">
              <p>
                В максимальной степени, разрешенной законом, {{ siteName }} исключает все условия, заверения, гарантии и
                другие условия, которые могли бы подразумеваться законодательными актами, общим правом или правом
                справедливости, и любую ответственность за убытки (или иные любые лишения), понесенные Вами в результате
                использования {{ siteName }}, услуг сайта, включая ответственность по искам, взысканиям, требованиям, убыткам,
                обязательствам, расходам любого характера и вида: прямым, косвенным, случайным, штрафным или косвенным
                убыткам (включая халатность); невозможность использования, потерю данных, ущерб, причиненный компьютеру
                электронным вирусом, потерю дохода или прибыли, утрату офисного времени, нарушение условий контракта или
                претензий третьих лиц или других потерь любого вида и характера, даже если {{ siteName }} был предупрежден о
                возможности такого ущерба или убытков, возникающих из или в связи с использованием веб сайта {{ siteName }},
                и/или его сервисов или использования партнерской административной панели.
              </p>
              <p>
                Данное ограничение ответственности распространяется, но не ограничивается, на передачу любых устройств
                или вирусов, которые могут заразить Ваше оборудование, стать причиной неисправности механического или
                электрического оборудования или линий связи, телефонной аппаратуры или других проблем связи (например,
                Вы не можете получить доступ к Интернету), несанкционированный доступ, кражу, телесные повреждения
                (кроме тех, что были вызваны нашей невнимательностью), материальный ущерб, ошибки оператора, забастовки
                или форс-мажорные обстоятельства, включая, но не ограничиваясь, ответственность за потерю доходов,
                потерю прибыли или контрактов, потерю бизнеса, потерю ожидаемой экономии, потерю репутации, потерю
                данных, потерю офисного времени и любые другие потери или ущерб любого рода.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Гарантия защиты от убытков</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__q">
              Привлечение в суд за несоблюдение правил поведения на сайте
            </p>
            <p class="rb-page--list__a increment">
              Если на наш сервис или компанию подают в суд в результате того, как Вы использовали веб-сайт {{ siteName }} или
              его сервисы, мы имеем право защищаться или погасить соответствующую претензию так, как мы считаем нужным.
              Если мы этого попросим, Вы, в полной мере, должны сотрудничать с нами в соответствии с требованиями по
              соответствующему иску.
            </p>
            <p class="rb-page--list__a increment">
              Вы соглашаетесь защищать и ограждать наш сервис, нас, наших служащих, директоров, сотрудников, агентов,
              представителей, партнеров от любых претензий третьих лиц, ущерба (прямого и/или косвенного), судебного
              разбирательства, требований, убытков, расходов (включая разумный гонорар адвоката), понесенных нами и
              возникших в результате, или в связи с Вашими действиями на веб сайте {{ siteName }} и /или его сервисах, или
              контентом, который Вы загрузили на веб-сайт {{ siteName }} и/или его сервисы, или Вашим поведением, кроме как в
              соответствии с положениями настоящего соглашения или в соответствии с действующим законодательством.
              {{ siteName }} оставляет за собой исключительное право разрешать и оплачивать любые претензии или основания для
              иска, которые обращены против нас без Вашего предварительного согласия. В случае необходимости мы
              вынуждены будем взыскать с Вас причиненный нам ущерб.
            </p>
            <p class="rb-page--list__a increment">
              Нарушив положения, раздела 15, настоящего соглашения, Вы единолично несете ответственность за свои
              действия. В данном случае Ваша учетная запись может быть безвозвратно или временно заблокирована, на
              Вашего представителя могут быть наложены штрафные санкции и материалы о незаконной деятельности могут для
              дальнейшего разбирательства быть переданы в ИНТЕРПОЛ и правоохранительные органы Вашей страны.
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Коммерческая тайна</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <div class="rb-page--list__a increment">
              <p>
                Коммерческой тайной является все содержание настоящего соглашения, каждый его раздел и пункт, а также
                любая информация о настоящем документе, которая включена в список документов компании
              </p>
              <p>Право на ознакомление с данным документом передано только Вам. </p>
              <p>
                Вам запрещено разглашать эти сведения любой другой третьей стороне (физическому или юридическому лицу),
                без добровольного согласия уполномоченного представителя компании {{ siteName }} (факт наличия которого Вы
                будете должны впоследствии подтвердить). За нарушение данного положения Вы несете полную юридическую
                ответственность в соответствии с действующим международным и/или местным законодательством.
              </p>
              <p>
                В случае, если уполномоченные государственные правоохранительные или судебные органы будут требовать, в
                установленной форме, от Вас раскрыть любую информацию относительно данного документа, Вы обязаны
                оповестить (через форму обратной связи на сайте или телефонную связь, или через переписку)
                уполномоченного представителя компании {{ siteName }} и получить его согласие на данные действия (факт наличия
                которого Вы будете должны впоследствии подтвердить).
              </p>
              <p>
                Предупреждаем, что {{ siteName }} будет вынужден защищать данное положение всеми законными средствами, а также
                может безвозвратно или временно заблокировать Вашу учетную запись. Кроме того, на Вашего представителя
                могут быть наложены штрафные санкции.
              </p>
            </div>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Споры</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <p class="rb-page--list__a increment">
              Вы должны признать, что все действия с участием Вашего профайла на нашем веб-сервисе и приложениях,
              включая покупку товаров или услуг, происходят территориально в 1064 Budapest, Podmaniczky u. 57, II em.
              14. Вы соглашаетесь с тем, что любые споры или претензии, как-либо связанные с Вашим
              посещением или использованием веб-сайта и/или его услуг, или связанные с использованием любых продуктов
              и/или сервисов от компании {{ siteName }}, Вы сначала попытаетесь решить неофициально, связавшись с нами. Если
              спор не будет урегулирован в течение 30 дней после Вашего обращения, Вы или наша компания должны разрешить
              любые претензии, связанные с настоящими условиями, услугами или веб-сервисом от нашей компании в
              арбитражном суде, расположенном территориально по месту нахождения нашей компании. Решение этого суда
              будет окончательным и обязывающим, кроме случаев, когда Вы вправе отстаивать свои претензии в суде мелких
              тяжб.
            </p>
            <p class="rb-page--list__a increment">
              Запрет на групповые иски. Вы имеете право регулировать споры с нами в
              индивидуальном порядке и не имеете права подавать объединенные или групповые иски.
            </p>
          </li>
        </ol>
      </li>
      <li class="increment">
        <h2>Прочие условия</h2>
        <ol class="rb-page--list rb-page--list__sub list-unstyled">
          <li class="rb-page--list__li">
            <div class="rb-page--list__a increment">
              <p>
                Сайт {{ siteName }} обязуется предоставлять своевременную информацию по принципу “как есть”, а также
                предпринимает все возможные меры для общей доступности, достоверности, полноты информации, содержащейся
                на веб-сайте {{ siteName }} и его сервиса. А также {{ siteName }} не дает никаких прямых, подразумеваемых или
                косвенных гарантий и обязательств в отношении информации, содержащейся на {{ siteName }}. Вы принимаете на себя
                ответственность за использование веб-сайта {{ siteName }} и его сервисов, и любых материалов доступных в
                приложении на свой риск. {{ siteName }} не несет никакой ответственности за утрату данных во время передачи и
                использования, а также за информацию некорректного содержания, предоставленную пользователями и/или
                партнерами. Вы принимаете на себя ответственность за все возможные меры предосторожности по избежанию
                заражения компьютерными вирусами во время использования веб сайта {{ siteName }} и его сервисов.
              </p>
              <p>
                Настоящим соглашением Вы принимаете то, что во время использования приложения могут возникать ошибки и
                неполадки, которые не подлежат исправлению, а также то, что веб-сайт {{ siteName }}, его сервисы или сервер
                могут быть подвергнуты атакам вирусов, шпионского ПО, «троянов» или другого вредоносного программного
                обеспечения. {{ siteName }} не несет ответственности за порчу технического или программного обеспечения, за
                любой ущерб или вред имущественного характера, в том числе в случае претензий по поводу возмещения
                какого-либо ущерба, причинённого в связи с какой-либо ошибкой, упущением, вирусом, несанкционированным
                доступом, мошенничеством, взломом, задержками в работе веб приложения. {{ siteName }} не несет ответственности
                за какой-либо ущерб, причиненный в связи с любыми неполадками в сети интернет или любыми другими
                техническими сбоями.
              </p>
            </div>
            <div class="rb-page--list__a increment">
              <p>
                Мы имеем право на наше личное усмотрение и без возникновения каких-либо обязательств изменять и
                вносить корректировки в настоящее соглашение в любое время.
              </p>
              <p>
                Все изменения будут отображены на данной странице (в конце настоящего соглашения) с указанием даты,
                когда такие изменения вступили в силу. В некоторых случаях мы можем извещать Вас об изменениях по
                электронной почте. С целью избежать недоразумений в связи с внесенными изменениями Вы принимаете на себя
                обязательство время от времени просматривать данную страницу.
              </p>
              <p>
                Если Вы не принимаете какого-либо из изменений этих условий в настоящем соглашении, Вы обязаны
                немедленно прекратить использовать {{ siteName }} и подать заявку на деактивацию Вашей учетной записи. Если Вы
                продолжаете пользоваться веб-сайтом {{ siteName }}, его сервисами после изменений в настоящем соглашении, то Вы
                автоматически принимаете обновленные условия.
              </p>
            </div>
            <p class="rb-page--list__a increment">
              Если, по какой-либо причине, любая из договоренностей данного
              соглашения признана судом соответствующей юрисдикции незаконной или юридически недействительной, в этом
              случае такая договоренность будет удалена из соглашения или будет отредактирована без особых изменений
              остальных обязательных условий и их юридической силы.
            </p>
            <p class="rb-page--list__a increment">
              Настоящие условия и положения являются соглашением между нами и
              Вами как пользователем, который использует наш информационный ресурс, и заменяют собой все (письменные или
              устные) предыдущие заявления, соглашения и договоренности между нами. Данный пункт никаким образом не
              ограничивает и не исключает любую ответственность за намеренное введение в заблуждение или умышленное
              искажение фактов.
            </p>
            <p class="rb-page--list__a increment">
              Задержка в осуществлении любого полномочия, права или
              привилегии, а также невыполнение вышеперечисленного в соответствии с данным соглашением не является
              отказом от такого права или принятием любых изменений в положениях, а также частичное осуществление любой
              из сторон любого права, полномочия или привилегии не исключает дальнейшего осуществления этого права или
              осуществления любого другого права, полномочия или привилегии.
            </p>
            <p class="rb-page--list__a increment">
              Ничто в положениях настоящего соглашения не предоставляет или не
              имеет целью придать любой другой третьей стороне пользу или право обеспечения любого иного другого
              соглашения, или каких-либо других договоров (Права третьих сторон).
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    full: Boolean, // enable links and so on
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters.siteSettings;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
  .important {
    margin-top: 10px;
    text-transform: uppercase;
  }

  p {
    margin-bottom: .5em;
  }

  .ul-styled {
    li {
      margin-bottom: .5em;
    }
  }
</style>
