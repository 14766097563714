<template>
  <div class="rb-page cookie-page">
    <div class="container">
      <div
        class="white-box"
        :class="{unselectable: isGuest}"
        @contextmenu="interact"
        @copy="interact"
        @cut="interact"
      >
        <div class="row">
          <div
            v-if="sex.isWoman"
            class="col-12"
          >
            <h1 class="rb-page--header">
              Политика Cookie
            </h1>
            <p class="cookie-page--header__sub">
              Настоящая политика описывает наше использование файлов cookie. Пожалуйста, ознакомьтесь с Политикой
              конфиденциальности чтобы мы могли использовать ваши личные данные.
            </p>
            <p class="cookie-page--header__sub">
              Веб-сайт {{ siteName }} обязуется защищать вашу личную информацию. Нашей целью является предоставление надежных
              услуг, которые являются ведущими в отрасли, чтобы вы могли сосредоточиться на поиске серьезных знакомств.
              Наш подход к конфиденциальности состоит в том, чтобы предоставлять вам четкую информацию о том, как мы
              обрабатываем данные. Поэтому мы постарались свести к минимуму техническую и юридическую лексику.
            </p>
            <p>
              Данный раздел описывает то, как мы используем куки-файлы и аналогичные технологии. Предоставляя нам свое
              согласие с этим уведомлением о cookie- технологии, вы соглашаетесь с тем, что мы можем различать вас на
              ряду с другими пользователями нашего веб-сайта, а также приложений в целях, изложенных ниже. Ваше согласие
              помогает нам предоставлять запрашиваемые вами услуги, отличную пользовательскую связь, адаптированный под
              ваши нужды и интересы контент, а также рекламу на основе ваших предпочтений. Например, мы можем опознавать
              и запоминать важную информацию, среди которой есть предпочтительные настройки, что помогает вам облегчать
              просмотр нашего веб-сайта.
            </p>
            <div class="cookie-page--title">
              <h2>Что такое Cookies и аналогичные технологии?</h2>
            </div>
            <p>
              Файл cookie это небольшой файл, состоящий из букв и цифр, который мы сохраняем используя ваш браузер на
              жестком диске вашего мобильного устройства либо компьютера.
            </p>

            <p class="cookie-page--text">
              Всего есть три основных типа файлов cookie:
            </p>
            <ul class="ul-styled">
              <li>
                <strong>Сессионные куки: </strong>такие файлы разработаны для определенного визита
                на веб-сайт и ограничены отправкой идентификаторов сеансов (случайные числа, которые генерирует сервер),
                поэтому когда вы переходите на новую страницу или покидаете ее, вам не нужно заново вводить информацию.
                Сессионные файлы cookie не хранятся на вашем устройстве и не стираются при закрытии браузера;
              </li>
              <li>
                <strong>Постоянные файлы cookie:</strong> такие файлы записывают информацию о ваших предпочтениях и
                сохраняют их в памяти браузера или на вашем мобильном устройстве;
              </li>
              <li>
                <strong>Сторонние куки-файлы:</strong> такие файлы размещены в сети кем-то другим, но не нами. Эти
                файлы могут собирать информацию и данные сразу на нескольких сайтах или сессиях.
              </li>
            </ul>
            <p>
              Также мы можем автоматически собирать и хранить конкретную информацию о вашей взаимосвязи с нашим
              веб-сайтом или приложением. Такая информация включает в себя IP-адрес, тип браузера, провайдера
              интернет-услуг, страницы ссылок и страницы выхода, операционную систему, даты и временные отметки и другие
              смежные данные.
            </p>
            <div class="cookie-page--title">
              <h2>Другие технологии</h2>
            </div>
            <p class="cookie-page--text">
              Параллельно с файлами cookie, мы можем использовать другие подобные
              технологии:
            </p>
            <ul class="ul-styled">
              <li>
                <strong>Веб-маяки: </strong> небольшие графические картинки, включенные на наш веб-сайт или
                электронные письма или сообщения третьих лиц для предоставления аналитической информации
              </li>
              <li>
                <strong>Социальные виджеты: </strong>это кнопки или значки, которые нам предоставляют социальные сети
                третьих лиц, которые могут собирать информацию о просмотре и будут получены третьей стороной и
                контролируются третьей стороной.
              </li>
              <li>
                <strong>Комплекты для разработки программного обеспечения: </strong> это мобильные приложения для
                разработчиков программного
                обеспечения для мобильных приложений, которые мы используем в наших приложениях. Они позволяют нам
                собирать информацию об активности приложения и устройства, на котором оно работает.
              </li>
              <li>
                <strong>Локальные общие объекты:</strong> иногда их называют флеш-файлами, они могут храниться в вашем
                браузере. Они используются для сохранения ваших предпочтений и учетов об использовании.
              </li>
            </ul>
            <div class="cookie-page--title">
              <h2>Cookies, которые мы используем</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Название cookie-файла</th>
                    <th>Для чего мы его используем</th>
                    <th>Истечение срока действия</th>
                    <th>Необходимость (да/нет)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Access-token</td>
                    <td>Авторизованный токен. Он необходим для доступа к личной учетной записи</td>
                    <td>1 месяц</td>
                    <td>да</td>
                  </tr>
                  <tr>
                    <td>Chat-token</td>
                    <td>Токен чата. Используется для отклонения инвайта (приглашения)</td>
                    <td>сессия</td>
                    <td>да</td>
                  </tr>
                  <tr>
                    <td>locale</td>
                    <td>Язык интерфейса сайта. Используется для определения языка сайта</td>
                    <td>1 месяц</td>
                    <td>да</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cookie-page--title">
              <h2>Сторонние файлы cookie</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Имя файла cookie</th>
                    <th>Почему он используется</th>
                    <th>Компания</th>
                    <th>Политика конфиденциальности третьих лиц</th>
                    <th>Элементы управления пользователя</th>
                    <th>Необходимость (да/ нет)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Facebook</td>
                    <td>Функциональность рекламы / маркетинга / социальных сетей</td>
                    <td>Facebook</td>
                    <td>
                      <a
                        href="https://www.facebook.com/about/privacy/update"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Гугл Аналитика</td>
                    <td>Реклама / Маркетинг</td>
                    <td>Google</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy/update"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Mixpanel</td>
                    <td>Мобильная аналитика</td>
                    <td>Mixpanel</td>
                    <td>
                      <a
                        href="https://mixpanel.com/legal/privacy-policy/"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Yandex.Метрика</td>
                    <td>Реклама / Маркетинг</td>
                    <td>Yandex</td>
                    <td>
                      <a
                        href="https://yandex.ru/legal/confidential/"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Hubspot</td>
                    <td>Реклама / Маркетинг</td>
                    <td>Hubspot</td>
                    <td>
                      <a
                        href="https://legal.hubspot.com/privacy-policy"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Survicate</td>
                    <td>Опрос пользователей</td>
                    <td>Survicate</td>
                    <td>
                      <a
                        href="https://help.survicate.com/account-and-billing/privacy-policy"
                        target="_blank"
                      >Узнать
                        больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                  <tr>
                    <td>Intercom</td>
                    <td>Живой чат</td>
                    <td>Intercom</td>
                    <td>
                      <a
                        href="https://www.intercom.com/terms-and-policies#privacy"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>Через настройки браузера</td>
                    <td>нет</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cookie-page--title">
              <h2>Другие аналогичные технологии, используемые в наших приложениях</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Почему мы его используем</th>
                    <th>Политика конфиденциальности третьих лиц</th>
                    <th>Пользовательский контроль</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Mixpanel</td>
                    <td>Маркетинговые push-уведомления (iOS и Android), тестирование A / B</td>
                    <td>
                      <a
                        href="https://mixpanel.com/legal/privacy-policy/"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                    <td>
                      <a
                        href="https://help.mixpanel.com/hc/articles/360000679006-Managing-Personal-Information"
                        target="_blank"
                      >Узнать больше</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              Вы должны подтвердить, что если мы разрешаем третьим сторонам, которые указаны выше, отказаться от файлов
              cookie, то в этом случае мы не контролируем ни файлы cookie ни любые генерируемые данные. Пожалуйста,
              ознакомьтесь с политикой конфиденциальности третьей стороны для получения информации о том, какую
              информацию они собирают и как они ее используют.
            </p>
            <div class="cookie-page--title">
              <h2>Отказ от файлов cookie или их удаление </h2>
            </div>
            <p class="cookie-page--text">
              Чтобы настроить настройки браузера, нажмите следующие ссылки:
            </p>
            <ul class="ul-styled">
              <li>
                <a
                  href="https://support.microsoft.com/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                  target="_blank"
                >Internet Explorer</a>
              </li>
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647"
                  target="_blank"
                >Google Chrome</a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored"
                  target="_blank"
                >Mozilla</a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored"
                  target="_blank"
                >Firefox</a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/kb/ph21411"
                  target="_blank"
                >Safari</a>
              </li>
            </ul>

            <p>
              Обратите внимание, что вы не сможете воспользоваться всеми функциями нашего веб-сайта или приложений,
              если вы удаляете или отказываетесь от куки-файлов.
            </p>

            <div class="cookie-page--title">
              <h2>Мобильный отказ от применения</h2>
            </div>
            <p>
              Если вы получаете доступ к нашим услугам через наши приложения, вы можете включить функцию «не
              отслеживать», чтобы контролировать рекламу на основе интересов на мобильном устройстве iOS или Android,
              выбрав параметр
              «Ограничить добавление отслеживания» в разделе конфиденциальности ваших настроек на iOS или с помощью
              рекламных предпочтений на устройствах Android (например, в настройках Google). Это не помешает отображению
              рекламных объявлений, но будет означать, что они больше не будут персонализированы для ваших интересов.
            </p>
            <p>
              Чтобы отказаться от использования Google Analytics, перейдите на страницу
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >https://tools.google.com/dlpage/gaoptout</a>.
            </p>
            <p>
              Дополнительную информацию об управлении куки файлами можно найти на сайте
              <a
                href="http://www.allaboutcookies.org"
                target="_blank"
              >www.allaboutcookies.org</a>.
            </p>
            <div class="cookie-page--title">
              <h2>Если вы являетесь резидентом ЕС:</h2>
            </div>
            <p>
              Для получения дополнительной информации об управлении куки файлами посетите сайт
              <a
                href="http://www.youronlinechoices.eu"
                target="_blank"
              >www.youronlinechoices.eu</a>,
              в котором содержится дополнительная информация о поведенческой рекламе и конфиденциальности в Интернете.
            </p>
          </div>
          <div
            v-else
            class="col-12"
          >
            <h1 class="rb-page--header">
              Cookie Policy
            </h1>
            <p class="cookie-page--header__sub">
              This policy describes our use of cookies. For our use of your personal data, please read out Privacy
              Policy
            </p>
            <p class="cookie-page--header__sub">
              The {{ siteName }} website is committed to protect your personal information. Our goal is to provide reliable
              services that are leading in the industry so you can focus on finding serious relationship. Our approach
              to privacy is to provide you with clear information about how we process data. Therefore, we have tried to
              minimize technical and legal vocabulary.
            </p>
            <p>
              This section describes how we use cookies and similar technologies. By agreeing to us with this cookie
              technology notice, you agree that we can distinguish you along with other users of our website and
              applications for the purposes outlined below. Your acceptance helps us deliver the services you request,
              great user experience, content adapted to your needs and interests, as well as advertising based on your
              preferences. For example, we can identify and memorize important information, among which are preferable
              settings, which helps you to make it easier to view our website.
            </p>
            <div class="cookie-page--title">
              <h2>What are cookies and similar technologies?</h2>
            </div>
            <p>
              A cookie is a small file of letters and numbers that we save using your browser on the hard disk of your
              mobile device or computer.
            </p>

            <p class="cookie-page--text">
              There are three main types of cookies:
            </p>
            <ul class="ul-styled">
              <li>
                <strong>Session cookies:</strong> these files are designed for a specific visit to the website and are
                limited to
                sending session identifiers (random numbers generated by the server), so when you go to a new page or
                leave it, you do not need to re-enter the information. Session cookies are not stored on your device and
                are not erased when you close your browser;
              </li>
              <li>
                <strong>Persistent cookies:</strong> these files record information about your preferences and store
                them in the
                browser’s memory or on your mobile device;
              </li>
              <li>
                <strong>Third-party cookies:</strong> such files are placed on the network by someone else, but not by
                us. These files
                can collect information and data on several sites or sessions at once.
              </li>
            </ul>
            <p>
              We can also automatically collect and store specific information about your cooperation with our website
              or application. Such information includes the IP address, type of browser, Internet service provider,
              links and exit pages, operating system, date and time stamps, and other related data.
            </p>
            <div class="cookie-page--title">
              <h2>Other technologies</h2>
            </div>
            <p class="cookie-page--text">
              In parallel with cookies, we can use other similar technologies:
            </p>
            <ul class="ul-styled">
              <li>
                <strong>Web beacons:</strong> small graphic images included on our website or emails or messages from
                third parties to
                provide analytical information.
              </li>
              <li>
                <strong>Social widgets:</strong> these are buttons or icons that provide us with social networks of
                third parties that
                can collect information about viewing and will be received by a third party and controlled by a third
                party.
              </li>
              <li>
                <strong>Software development kits:</strong> these are mobile applications for software developers for
                mobile
                applications that we use in our applications. They allow us to collect information about the activity of
                the application and the device on which it works.
              </li>
              <li>
                <strong>Local shared objects:</strong> sometimes they are called flash files, they can be stored in
                your browser. They are used to save your preferences and usage considerations.
              </li>
            </ul>
            <div class="cookie-page--title">
              <h2>Cookies we use</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Name of Cookie</th>
                    <th>Why we use it</th>
                    <th>Expiration</th>
                    <th>Essential? (yes/no)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Access-token</td>
                    <td>Authorization token. It’s necessary for access to the personal account</td>
                    <td>1 month</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Chat-token</td>
                    <td>Token for chat. It is used to decline the invite</td>
                    <td>Session</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>locale</td>
                    <td>Site interface language</td>
                    <td>1 month</td>
                    <td>Yes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cookie-page--title">
              <h2>Third party cookies</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Name of Cookie</th>
                    <th>Why it is used</th>
                    <th>Company</th>
                    <th>Third party privacy policies</th>
                    <th>User controls</th>
                    <th>Essential? (yes/no)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Facebook</td>
                    <td>Advertising/Marketing/social media functionality</td>
                    <td>Facebook</td>
                    <td>
                      <a
                        href="https://www.facebook.com/about/privacy/update"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Google Analytics</td>
                    <td>Advertising/Marketing</td>
                    <td>Google</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy/update"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Mixpanel</td>
                    <td>Mobile analytics</td>
                    <td>Mixpanel</td>
                    <td>
                      <a
                        href="https://mixpanel.com/legal/privacy-policy/"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Yandex.Metrika</td>
                    <td>Advertising/Marketing</td>
                    <td>Yandex</td>
                    <td>
                      <a
                        href="https://yandex.ru/legal/confidential/"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Hubspot</td>
                    <td>Advertising/Marketing</td>
                    <td>Hubspot</td>
                    <td>
                      <a
                        href="https://legal.hubspot.com/privacy-policy"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Survicate</td>
                    <td>User survey</td>
                    <td>Survicate</td>
                    <td>
                      <a
                        href="https://help.survicate.com/account-and-billing/privacy-policy"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Intercom</td>
                    <td>Live chat</td>
                    <td>Intercom</td>
                    <td>
                      <a
                        href="https://www.intercom.com/terms-and-policies#privacy"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>Through browser settings</td>
                    <td>No</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cookie-page--title">
              <h2>Other similar technologies used in our apps</h2>
            </div>
            <div class="cookie-page--table-wrap">
              <table class="b-docs-table is-align-top">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Why we use it</th>
                    <th>Third party privacy policy</th>
                    <th>User control</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Mixpanel</td>
                    <td>Marketing push notifications (iOS and Android), A/B testing</td>
                    <td>
                      <a
                        href="https://mixpanel.com/legal/privacy-policy/"
                        target="_blank"
                      >Find out more</a>
                    </td>
                    <td>
                      <a
                        href="https://help.mixpanel.com/hc/articles/360000679006-Managing-Personal-Information"
                        target="_blank"
                      >Find out more</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              You must confirm that if we allow third parties, as indicated above, to refuse cookies, in this case we
              do not control either the cookies or any data generated. Please review the third party privacy policy for
              information on what sort of information they collect and how they use it.
            </p>
            <div class="cookie-page--title">
              <h2>Refusing or deleting cookies</h2>
            </div>
            <p class="cookie-page--text">
              To configure your browser settings, click the following links:
            </p>
            <ul class="ul-styled">
              <li>
                <a
                  href="https://support.microsoft.com/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                  target="_blank"
                >Internet Explorer</a>
              </li>
              <li>
                <a
                  href="https://support.google.com/chrome/answer/95647"
                  target="_blank"
                >Google Chrome</a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored"
                  target="_blank"
                >Mozilla</a>
              </li>
              <li>
                <a
                  href="https://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored"
                  target="_blank"
                >Firefox</a>
              </li>
              <li>
                <a
                  href="https://support.apple.com/kb/ph21411"
                  target="_blank"
                >Safari</a>
              </li>
            </ul>

            <p>
              Please note that you will not be able to use all the functions of our website or applications if you
              delete or refuse cookies.
            </p>

            <div class="cookie-page--title">
              <h2>Mobile Opt-out</h2>
            </div>
            <p>
              When you access our services via our applications, you can enable the “do not track” function to monitor
              interest-based ads on your iOS or Android mobile device by selecting the option
              “Restrict adding tracking” in the privacy section of your settings on iOS or using advertising preferences
              on Android devices (for example, in Google settings). This will not prevent the display of advertisements,
              but will mean that they will no longer be personalized for your interests.
            </p>
            <p>
              To stop using Google Analytics, go to
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >https://tools.google.com/dlpage/gaoptout</a>.
            </p>
            <p>
              Additional information on managing cookies can be found at
              <a
                href="http://www.allaboutcookies.org"
                target="_blank"
              >www.allaboutcookies.org</a>.
            </p>
            <div class="cookie-page--title">
              <h2>If you are a resident of the EU:</h2>
            </div>
            <p>
              For more information on managing cookies, visit
              <a
                href="http://www.youronlinechoices.eu"
                target="_blank"
              >www.youronlinechoices.eu</a>,
              which provides additional information about behavioral advertising and online privacy.
            </p>
          </div>
        </div>
      </div>
    </div>
    <toasts />
    <reg-modal />
    <confirm-restore-profile v-if="restoreModal.open" />
  </div>
</template>

<script>
import Toasts from '../../Authorized/Toast.vue';
import RegModal from '../modals/RegisterModal.vue';
import ConfirmRestoreProfile from '../modals/ConfirmRestoreProfile.vue';
import { LOAD_PROFILE, SELECTION } from '../../../mixins/types/static';

export default {
  components: {
    Toasts,
    RegModal,
    ConfirmRestoreProfile,
  },
  mixins: [LOAD_PROFILE, SELECTION],
  data() {
    return {};
  },
  computed: {
    restoreModal() {
      return this.$store.getters.restoreProfileModal;
    },
    sex() {
      return this.$store.getters.sex;
    },
    siteName() {
      return this.$store.getters.siteSettings.site_name
    },
  },
  mounted() {
    this.sex.isWoman ? document.title = 'Политика Cookie' : document.title = 'Cookie Policy';
  },
};
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/vars";

  p,.rb-page .ul-styled li{
    margin-bottom: .5em;
  }

  .cookie-page {
    &--header {
      color: $black;
      font-size: 26px;
      font-weight: 400;
      line-height: 26px;

      &::first-letter {
        color: $red;
      }

      &__sub {
        color: $black;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        letter-spacing: 1.4px;
        margin-bottom: .8rem;
      }
    }

    &--text {
      color: $black;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1.4px;
    }

    &--title {
      overflow: hidden;
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        display: inline;

        &:after {
          content: "";
          display: inline-block;
          height: 1px;
          vertical-align: middle;
          width: 100%;
          margin-right: -100%;
          margin-left: 10px;
          background: #d9d9d9;
        }
      }
    }

    &--table-wrap {
      overflow: auto;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #1a1a1a;
      margin-bottom: 20px;

      th {
        background-color: $grey;
        font-weight: 700;
        font-size: 15px;

      }

      tr {
        &:nth-child(even) {
          background: $light-grey;
        }

        td {
          &:first-child {
            font-weight: 700;
          }
        }
      }

      td, th {
        border: 1px solid #1a1a1a;
        padding: 1rem 2rem;
      }
    }
  }
</style>
